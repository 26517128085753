import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {useToasts} from 'react-toast-notifications'
import {useTranslation} from 'react-i18next'

import ApiService from '@services/api.service'
import formateDate from '@utils/format-date'
import useLocale from '@hooks/useLocale'
import DoctorFormModal from './DoctorFormModal'
import makeUser from '@models/makeUser'
import {getUser} from '@services/jwt.service'

import Layout from '@pages/Layout'
import ImagePlacholder from '@assets/imgs/image-placholder.png'

import BackButton from '@components/BackButton'
import Button from '@components/Button'
import Row from '@components/Row'
import Loader from '@components/Loader'
import {DoctorLanguages} from '@utils/doctor-languages'
import axios from "axios";

function DoctorsTable({doctors, editDoctor}) {
    const {t} = useTranslation()
    const history = useHistory()
    const {addToast} = useToasts()

    return (
        <table className="c-table" style={{minWidth: '1200px'}}>
            <thead className="bg-gray-50">
                <tr>
                    <th className="px-1 sm:px-4 w-12 border-l border-gray-75 text-center">#</th>
                    <th className="px-1 sm:px-4">{t("Image")}</th>
                    <th className="px-1 sm:px-4">{t("Specilization")}</th>
                    <th className="px-1 sm:px-4">{t("Title")}</th>
                    <th className="px-1 sm:px-4">{t("Name")}</th>
                    <th className="px-1 sm:px-4">{t("Phone")}</th>
                    {/* <th className="px-1 sm:px-4">{t("Email")}</th> */}
                    {/* <th className="px-1 sm:px-4 text-center">{t("About (Arabic)")}</th> */}
                    {/* <th className="px-1 sm:px-4 w-32">{t("About (English)")}</th> */}
                    <th className="px-1 sm:px-4">{t("Languages")}</th>
                    <th className="px-1 sm:px-4 text-center">{t("Experience")}</th>
                    <th className="px-1 sm:px-4 text-center">{t("Doctor Type")}</th>
                    <th className="px-1 sm:px-4 text-center">{t("Created at")}</th>
                    <th className="px-1 sm:px-4 text-center">{t("Active")}</th>
                    <th className="px-1 sm:px-4 text-center w-64">{t("Actions")}</th>
                </tr>
            </thead>
            <tbody>
                {doctors?.map((doctor, index) => {
                    return (
                        <tr key={index.toString()}>
                            <td className="px-2 sm:px-4 py-3 border-l border-gray-50 text-center">{ (index + 1).toString() }</td>
                            <td className="px-2 sm:px-4 py-3 w-20 md:w-40">
                                <img src={doctor.image ? doctor.image : ImagePlacholder} className="w-12 h-12 rounded-full border border-gray-75 object-cover"></img>
                            </td>
                            <td className="px-2 sm:px-4 py-3">{doctor.specialization.title}</td>
                            <td className="px-2 sm:px-4 py-3">{doctor.title}</td>
                            <td className="px-2 sm:px-4 py-3">{doctor.name}</td>
                            <td className="px-2 sm:px-4 py-3">{doctor.phone}</td>
                            {/* <td className="px-2 sm:px-4 py-3">{doctor.email}</td> */}
                            {/* <td className="px-2 sm:px-4 py-3">{doctor.about_ar}</td> */}
                            {/* <td className="px-2 sm:px-4 py-3">{doctor.about_en}</td> */}
                            <td className="px-2 sm:px-4 py-3">{ doctor.languages.map(lang => <span className="px-2"> { t(DoctorLanguages[lang]) }</span> ) }</td>
                            <td className="px-2 sm:px-4 py-3 text-center">{doctor.experience}</td>
                            <td className="px-2 sm:px-4 py-3 text-center capitalize">{doctor.type}</td>
                            <td className="px-2 sm:px-4 py-3">{formateDate(doctor.created_at)}</td>
                            <td className="px-2 sm:px-4 py-3">
                                <input type="checkbox" defaultChecked={doctor.active}
                                       onClick={e => e.stopPropagation()}
                                       onChange={e=> {
                                           let input = e.target
                                           e.stopPropagation()
                                           input.disabled = true
                                           axios.patch(`/api/doctors/${doctor.id}/activation?active=${!doctor.active * 1}`)
                                               .then(() =>{
                                                   doctor.active = ! doctor.active
                                                   addToast(doctor.active ? t('Activated successfully') : t('Deactivated successfully'), {appearance: 'success'})
                                               })
                                               .catch(e => addToast(e.response.data.message, {appearance: 'error'}))
                                               .finally(() => input.disabled = false)
                                       }}
                                />
                            </td>
                            <td className="px-2 sm:px-4 py-3 border-r border-gray-50 text-center">
                                <button className="px-2 my-1 py-1 text-xs border border-primary text-primary rounded-md me-2" onClick={() => history.push(`/appointments/${doctor.id}`)}>{t("Appointments")}</button>
                                <button className="px-2 my-1 py-1 text-xs border border-primary text-primary rounded-md me-2" onClick={() => history.push(`/doctors/${doctor.id}`)}>{t("View")}</button>
                                <button className="px-2 my-1 py-1 text-xs border border-primary text-primary rounded-md me-2" onClick={() => history.push(`/doctors/${doctor.id}/shifts`)}>{t("Working Hours")}</button>
                                <button className="px-2 my-1 py-1 text-xs border border-primary text-primary rounded-md" onClick={() => editDoctor(doctor)}>{t("Edit")}</button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
export default function Clinic() {
    const [clinic, setClinic] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const {id} = useParams()
    const {addToast} = useToasts()
    const {t} = useTranslation()
    const locale = useLocale()
    const [editDoctor, setEditDoctor] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const currentUser = makeUser(getUser())

    function openModal(doctor = {}) {
        setEditDoctor(doctor)
        setModalIsOpen(true)
    }

    function closeModal() {
        setModalIsOpen(false)
    }

    useEffect(() => {
        fetchClinic()
    }, [id])

    function onSuccess() {
        fetchClinic()
        closeModal()
    }

    async function fetchClinic() {
        try {
            setIsLoading(true)
            const response = await ApiService.get(`api/clinics/${id}`)
            setClinic(response['data']['data'])
        } catch(e) {
            if (e.response.status == 404) {
                addToast('Clinic not found.', {appearance: 'error'})
                history.push('/clinics')
                return
            }

            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)
        }
    }


    function Clinic() {
        return(
            <div>
                { currentUser.isAdmin() && <div className="pb-6 border-b border-gray-50">
                    <BackButton className="mr-auto"></BackButton>
                </div> }

                <h2 className="text-lg md:text-2xl text-center mt-6">{ clinic.name }</h2>

                <div className={isLoading ? 'hidden' : undefined}>
                    <div className="flex justify-between border-b border-gray-100 py-2">
                        <p className="font-bold text-primary text-xl py-2">{t("General Information")}</p>
                    </div>

                    <Row title={t("Arabic Name")} content={clinic.name_ar}></Row>
                    <Row title={t("English Name")} content={clinic.name_en}></Row>
                    <Row title={t("Phone")} content={clinic.phone}></Row>
                    <Row title={t("Email")} content={clinic.email}></Row>
                    <Row title={t("Location")} content={clinic.location}></Row>
                    <div>
                        <div className="flex justify-between border-b border-gray-100 py-2 mt-10">
                            <p className="font-bold text-primary text-xl py-2">{t("Doctors")}</p>
                            <div className="ms-auto">
                                <Button title={ t("Add Doctor") } onClick={openModal}></Button>
                            </div>
                        </div>
                        <div>
                            <DoctorsTable doctors={clinic?.doctors} editDoctor={(doctor) => openModal(doctor)}></DoctorsTable>
                            {clinic?.doctors?.length == 0 && <div className="h-48 flex justify-center items-center text-gray-500"> No Doctors Were Found </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Content() {
        return (
            <>
                {
                    isLoading ? <Loader></Loader> :
                    <>
                        <DoctorFormModal isOpen={modalIsOpen} close={() => closeModal()} onSuccess={() => onSuccess()} doctor={editDoctor} clinic={clinic}/>
                        <Clinic></Clinic>
                    </>
                }
            </>
        )
    }

    return (
        <Layout content={<Content />} title={ !isLoading && <> { clinic.name } </>}></Layout>
    )
}
