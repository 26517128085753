const initalState = {
    reservations: [], 
    search: '', 
    page: 1, 
    pagination: {}, 
    isLoading: false
}

const reservationsReducer = (state = initalState, action) => {
    switch(action.type) {
        case 'SET_RESERVATIONS':
            return {...state, reservations: action.reservations}
        case 'UPDATE_RESERVATION':
            return state.reservations.map((item, index) => {
                if (index !== action.index) {
                    return item
                }
                
                return {
                    ...item,
                    ...action.item
                }
            })
        case 'RESERVATIONS:SET_SEARCH':
            return {...state, search: action.search}
        case 'RESERVATIONS:SET_PAGINATION':
            return {...state, pagination: action.pagination}
        case 'RESERVATIONS:SET_PAGE':
            return {...state, page: action.page}
        default:
            return state
    }
}

export default reservationsReducer