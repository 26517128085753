import React from 'react'
import ReactDOM from 'react-dom'

import './assets/main.css'
import './assets/custom.css'
import App from './App'
import {Provider} from 'react-redux'
import {ToastProvider} from 'react-toast-notifications'
import store from './store'
import i18n from "./i18n";
import {I18nextProvider} from "react-i18next";
import useLocale from './hooks/useLocale'

window.store = store

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <ToastProvider autoDismiss>
                <Provider store={store}>
                    <App />
                </Provider>
            </ToastProvider>
        </I18nextProvider>
    </React.StrictMode>,

    document.getElementById('root')
)


document.getElementsByTagName('html')[0].setAttribute("dir", useLocale() == 'en' ? 'ltr' : 'rtl');
i18n.changeLanguage(useLocale() == 'en' ? 'en' : 'ar')
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
