import React, {useState} from 'react'

import {useToasts} from 'react-toast-notifications'
import {useTranslation} from 'react-i18next'
import {useForm} from "react-hook-form"
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import AuthService from '@services/auth.service'
import Button from '@components/Button'
import logo from '@assets/imgs/laan-black-logo.png'
import useLocale from '@hooks/useLocale'

function Locale() {
    const [locale, setLocale] = useState(useLocale())

    function toggleLocale() {
        localStorage.setItem('locale', locale == 'en' ? 'ar' : 'en')
        window.location.reload()
    }

    return (
        <div className="me-2">
            <button className="text-gray-400 leading-relaxed border border-gray-75 px-2 rounded text-sm py-1" onClick={toggleLocale}>
                { locale == 'en' ? 'عربي' : 'English' }
            </button>
        </div>
    )
}

export default function Login() {
    const {register, unregister, handleSubmit, errors, setValue} = useForm()
    const onSubmit = (data) => {
        console.log(data);
        login(data)
    }
    const onError = (errors, e) => console.log(errors, e)
    const [loading, setLoading] = useState(false)
    const [guard, setGuard] = useState('providers')
    const history = useHistory()
    const dispatch = useDispatch()
    const {addToast} = useToasts()
    const {t} = useTranslation()

    async function login({phone, password, email, guard}) {
        setLoading(true)
        try {
            await AuthService.login({phone, password, email, guard})
            history.push('/reservations')
            dispatch({type: 'LOG_IN'})
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
            setLoading(false)
        }
    }

    function onKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit(onSubmit, onError)()
        }
    }

    function onUserGuardChange(e) {
        setGuard(e.target.value)

        if (e.target.value == 'users') {
            unregister("phone")
            setTimeout(() => {setValue("email", "")}, 0);
        } else {
            unregister("email")
            setTimeout(() => {setValue("phone", "")}, 0);
        }

        unregister("password")
        setTimeout(() => {setValue("password", "")}, 0);
    }

    return (
        <div className="pt-4 md:pt-10 relative">
            <div className="w-full absolute left-0 right-0 h-64 bg-gray-700 top-0 z-0"></div>
            <div className="w-full max-w-lg rounded-md shadow p-10 bg-white fade-in-up animation-delay-200 mx-auto z-10">
                <img src={logo} className="mx-auto w-32 md:w-40 h-auto" alt="Logo"></img>
                {
                    guard == 'users'
                        ?
                    <div className="mt-4">
                        <label htmlFor="email" className="c-label">{t("Email")} {errors.email && <span className="ms-2 text-red-700 capitalize">{t("Email field is required")}</span>} </label>
                        <input name="email" id="email" type="text" className="c-input mt-1" placeholder="email@example.com" ref={register({required: true})} onKeyDown={onKeyDown}></input>
                    </div>
                        :
                    <div className="mt-4">
                        <label htmlFor="phone" className="c-label">{t("Phone Number")} {errors.phone && <span className="ms-2 text-red-700 capitalize">{t("phone field is required")}</span>} </label>
                        <input name="phone" id="phone" type="text" className="c-input mt-1" placeholder="05xxxxxxxx" ref={register({required: true})} onKeyDown={onKeyDown}></input>
                    </div>
                }


                <div className="mt-2">
                    <label htmlFor="password" className="c-label">{t("Password")} {errors.password && <span className="ms-2 text-red-700 capitalize">{t("password field is required")}</span>}</label>
                    <input name="password" id="password" type="password" className="c-input mt-1" placeholder={t("Password")} ref={register({required: true})} onKeyDown={onKeyDown}></input>
                </div>

                <div className="mt-2">
                    <label htmlFor="guard" className="c-label">{t("User Type")} {errors.guard && <span className="ms-2 text-red-700 capitalize">{t("User type field is required")}</span>}</label>
                    <select name="guard" className={`c-input mt-1 ${errors.type ? 'c-input--has-error' : ''}`} ref={register({required: true})} onChange={(e) => onUserGuardChange(e)} defaultValue="clinics">
                        <option value="providers">{t("Provider")}</option>
                        <option value="users">{t("Admin")}</option>
                        <option value="clinics">{t("Clinic")}</option>
                    </select>
                </div>

                <Button title={t("Login")} loading={loading} className="shadow-primary w-full h-10 text-lg mx-auto mt-10" onClick={handleSubmit(onSubmit)}></Button>

            </div>
            <div className="flex-center mt-10 fade-in-up animation-delay-200">
                <Locale></Locale>
            </div>
        </div>
    )
}
