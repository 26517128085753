import useLocale from '../hooks/useLocale'
import {unix} from "dayjs";

export default function(unixTimestamp, hour12 = true, locale = null) {
    if(!unixTimestamp){
        return ''
    }
    try {
        let usedLocale = locale ? locale :  useLocale() === 'en' ? 'en-GB' : 'ar-EG'
        return (new Intl.DateTimeFormat(usedLocale, {
            timeZone: 'Asia/Riyadh',
            hour : '2-digit',
            minute: '2-digit',
            hour12
        })).format(new Date(unixTimestamp * 1000))
    } catch(e) {
        console.error(e)
        return ''
    }
}
