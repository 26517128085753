import React from 'react'
import {useDispatch} from 'react-redux'
import {connect} from 'react-redux'

import Login from './Pages/Login'
import Employees from './Pages/Employees'
import Reservations from './Pages/Reservations'
import AllAppointments from './Pages/AllAppointments'
import Settings from './Pages/Settings'
import Clinics from './Doctors/Clinics'
import Specializations from './Doctors/Specializations'
import SingleReservation from './Pages/SingleReservation'
import SingleEmployee from './Pages/SingleEmployee'
import SingleClinic from './Doctors/SingleClinic'
import SingleDoctor from './Doctors/SingleDoctor'
import Appointments from './Doctors/Appointments'
import SingleAppointment from "./Doctors/SingleAppointment";

import ProtectedRoute from './ProtectedRoute'

import ApiService from '@services/api.service'
import AuthService from '@services/auth.service'
import {getUser} from '@services/jwt.service'

import Modal from 'react-modal'
import makeUser from './models/makeUser'
import LoadingOverlay from './components/LoadingOverlay'
import {Guard} from '@utils/guard'
import {loadScript} from '@utils/load-script';
import Shifts from "./Doctors/Shifts";


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom"

let lang = localStorage.getItem('locale');
if (!lang) {
    lang = 'ar';
}
loadScript(`https://maps.googleapis.com/maps/api/js?language=${lang}&key=AIzaSyCyaCgiWchfiZewtyULRG8FrfYenHVr8hc`);

window.AuthService = AuthService
window.ApiService = ApiService

Modal.setAppElement('#root')

ApiService.init() // Initilize JWT Token


function mapStateToProps(state) {
    return {
        logged: state.logged,
        user: makeUser(state.user)
    }
}

function App({logged, user}) {
    // AuthService.fetch().catch(e => {
    //     Redirect
    // })

    const dispatch = useDispatch()
    AuthService.isAuthenticated() && dispatch({type: 'LOG_IN'})
    const currentUser = makeUser(getUser())

    return (
        <Router>
            {
            // !currentUser.id ?
                // <LoadingOverlay></LoadingOverlay>
            // :
                <div className="bg-gray-50 h-screen overflow-y-scroll">
                    <Switch>
                        <Route exact path={["/", "/login"]}>
                            {!currentUser.id ? <Login></Login> : (
                                currentUser.isAdmin() ? <Redirect to={{pathname: '/clinics'}} /> : (
                                    currentUser.isClinic() ? <Redirect to={{pathname: `/clinics/${currentUser.id}`}} /> : <Redirect to={{pathname: '/reservations'}} />
                                )
                            )}
                        </Route>

                        <ProtectedRoute exact path="/employees" guards={[Guard.providers]} component={Employees} />
                        <ProtectedRoute exact path="/reservations" guards={[Guard.providers]} component={Reservations} />
                        <ProtectedRoute exact path="/settings" guards={[Guard.providers]} component={Settings} />
                        <ProtectedRoute exact path="/reservations/:id" guards={[Guard.providers]} component={SingleReservation}/>
                        <ProtectedRoute path="/employees/:id" guards={[Guard.providers]} component={SingleEmployee}/>

                        <ProtectedRoute exact path="/clinics" guards={[Guard.users]} component={Clinics} />
                        <ProtectedRoute exact path="/specializations" guards={[Guard.users]} component={Specializations} />
                        <ProtectedRoute exact path="/appointments" guards={[Guard.users]} component={AllAppointments} />
                        <ProtectedRoute exact path="/appointments/:id/show" guards={[Guard.users]} component={SingleAppointment} />
                        <ProtectedRoute exact path="/clinics/:id" guards={[Guard.users, Guard.clinics]} component={SingleClinic}/>
                        <ProtectedRoute exact path="/appointments/:id" guards={[Guard.users, Guard.clinics]} component={Appointments}/>
                        <ProtectedRoute exact path="/doctors/:id" guards={[Guard.users, Guard.clinics]} component={SingleDoctor}/>
                        <ProtectedRoute exact path="/doctors/:id/shifts" guards={[Guard.users, Guard.clinics]} component={Shifts}/>
                    </Switch>
                </div>
            }
        </Router>
    )
}

export default connect(mapStateToProps)(App)
