import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from "react-router-dom"
import {useToasts} from 'react-toast-notifications'
import {useTranslation} from 'react-i18next'

import Loader from '@components/Loader'
import Button from '@components/Button'
import BackButton from '@components/BackButton'

import store from '@/store'
import ApiService from '@services/api.service'
import useUser from '@hooks/useUser'
import Layout from '@pages/Layout'
import WorkingDaysTable from '@components/Providers/WorkingDaysTable'


function ServiceCard({initialService, employee}) {
    const [service, setService] = useState(initialService)
    const [truncate, setTruncate] = useState(true)
    const {addToast} = useToasts()

    async function toggleService(event) {
        const isChecked = event.target.checked
        const payload = {
            "servicesToAttach": [...(isChecked ? [service.id] : [])],
            "servicesToDetach": [...(!isChecked ? [service.id] : [])]
        }

        try {
            await ApiService.post(`/api/employees/${employee.id}/services-attach-detach`, payload)
            setService({...service, selected: isChecked})
        } catch(e) { 
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    return(
        <li className="c-service w-full md:w-1/3 px-2 py-1" key={service.id}>
            <span className="w-full flex justify-between p-2 border border-gray-75 rounded">
            <label className="w-full flex flex-1 items-center cursor-pointer font-medium" htmlFor={service.id}>
                    { service.selected && <svg className={`w-6 h-6 me-2 text-green-600 hover:text-green-700 flex-shrink-0`} viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg> }
                    { !service.selected && <svg className={`w-6 h-6 me-2 text-red-600 hover:text-red-700 flex-shrink-0`} viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg> }
                    <input className="me-2 hidden" type="checkbox" id={service.id} defaultChecked={service.selected} onChange={(e) => toggleService(e)} />
                </label>
                <span onClick={() => setTruncate(!truncate)} className={`w-full ${truncate ? 'truncate' : ''}`}>{ service.name }</span>
            </span>
        </li>
    )
}

function Duration({employee}) {
    const { t } = useTranslation()

    const [isEditing, setIsEditing] = useState(false)
    const [duration, setDuration] = useState(employee?.duration)
    
    async function updateDuration() {
        if (duration <= 0) {
            alert(t("Duration should be more than 1 minute"))
            return
        }

        ApiService.post(`/api/employees/${employee?.id}/duration`, {
            duration: duration
        })

        setIsEditing(false)
    }

    return(
        <>
            { 
                isEditing ?
                    (
                        <div className="flex">
                            <input className="c-input w-20 py-0 me-2" type="number" min="0" max="1440" step="1" defaultValue={duration} onChange={e => setDuration(e.target.value)}></input>
                            <button className="text-sm me-2 text-green-600" onClick={() => updateDuration()}>{t("Save")}</button>
                            <button className="text-sm me-2 text-red-600" onClick={() => {setIsEditing(false); setDuration(employee?.duration)}}>{t("Cancel")}</button>
                        </div>
                    )
                    :
                    (
                        <>
                            {duration} {t("Minute")}
                            <button className="text-sm ms-2" onClick={() => setIsEditing(true)}>{t("Edit")}</button>
                        </>
                    )
            }
        </>
    )
}

export default function Employee() {
    const {id} = useParams()
    const {t} = useTranslation()
    const {addToast} = useToasts()
    const employee = useSelector(state => state.employees.filter(e => e.id == id)[0])
    const provider = useUser()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [services, setServices] = useState([])

    useEffect(() => {
        if (employee) {
            fetchServices()
            setIsLoading(false)
        } else {
            fetchEmployees()
        }
    }, [employee])
    
    async function fetchEmployees() {
        try {
            setIsLoading(true)

            const response = await ApiService.get(`/api/providers/${provider.id}/employees`)
            
            store.dispatch({
                type: 'SET_EMPLOYEES',
                employees: response['data']['data']
            })

        } catch(e) {
            if (e.response.status == 404) {
                addToast('Employee not found.', {appearance: 'error'})
                history.push('/employees')
                return
            }

            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)
        }
    } 
    
    async function fetchServices() {
        const response = await ApiService.get(`/api/employees/${employee.id}/allowed-services?all=1`)
        setServices(response.data.data)
    }

    async function remove() {
        try {
            if (! window.confirm(t("Are you sure?"))) return
            ApiService.post(`/api/employees/${id}/delete`)
            history.push('/employees')
            addToast(t("Employee has been deleted"), {appearance: 'success'})
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    async function toggleActive(employeeId) {
        try {
            ApiService.post(`/api/employees/${employeeId}/active-inactive`)
            store.dispatch({
                type: 'TOGGLE_ACTIVE',
                id: employeeId
            })
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    function Row({borderless = false, title, content, className}) {
        return (
            <div className={`flex py-6 flex-col md:flex-row ${! borderless ? 'border-b border-gray-75' : '' } ${className}`}>
                <p className="text-gray-500 w-full md:w-1/6">{ title }</p>
                <div className="w-full font-bold text-primary">{ content }</div>
            </div>
        )
    }

    function Employee() {
        return(
            <>
                <div className="flex flex-col h-full">
                    <div className="pb-6 border-b border-gray-50">
                        <BackButton className="mr-auto"></BackButton>
                    </div>
                    
                    <div className="flex-grow pb-16">
                        <div className="text-2xl flex-center mt-4">
                            <img className="w-24 h-24 mx-auto object-cover object-center rounded-full border border-gray-75" src={provider?.logo} alt="Logo"></img>
                        </div>
                        <h2 className="text-xl text-center">{ employee?.name }</h2>

                        <div className="flex border-b border-gray-75 py-4">
                        </div>

                        <Row title={t("Name")} content={employee?.name}/>
                        <Row title={t("Gender")} content={employee?.serve}/>
                        <Row title={t("Status")} content={
                            <span className="flex items-center justify-between">
                                <div>
                                    <span className={`w-3 h-3 rounded-full me-2 inline-block ${employee?.is_active ? 'bg-green-500' : 'bg-gray-500'}`}></span>
                                    { employee?.is_active ? t("Active") : t("Deactivated") }
                                </div>
                                
                                <div>
                                    <Button title={employee?.is_active ? t("Deactivate") : t("Activate")} green={! employee?.is_active} onClick={()=>toggleActive(employee?.id)} className="ms-2"></Button>
                                </div>
                            </span>
                        }/>
                        
                        <Row title={t("Time Duration")} content={<Duration employee={employee}></Duration>}/>

                        <Row title={t("Working Days")} content={<WorkingDaysTable workingDays={employee?.workingDays.sort((a, b) => a.day > b.day ? 1 : -1 )}></WorkingDaysTable>}/>
                        
                        <Row borderless title={t("Services")} content={
                            <ul className="flex flex-wrap">
                                { services?.map(service => <ServiceCard key={`service-${service.id}`} initialService={service} employee={employee} />) }
                            </ul>
                        }/>
                    </div>
                    <div className="absolute bg-white border-gray-75 border-t bottom-0 flex h-16 items-center justify-end left-0 px-4 right-0 w-full">
                        <Button title={t("Delete")} onClick={remove} danger></Button>
                    </div>
                </div>
            </>
        )
    }

    function Content() {
        return(
            isLoading ? <Loader /> : <Employee />
        )
    }

    return (
        <Layout content={<Content />} title={employee?.name}></Layout>
    )
} 