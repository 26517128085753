import React from 'react'
import Modal from 'react-modal'
import Row from '@components/Row'
import {useTranslation} from "react-i18next";
import formateDate from "../utils/format-date";
import formatTime from "../utils/format-time";

export default function AppointmentModal({isOpen, close, appointment = {}}) {
    const {t} = useTranslation()

    if(!appointment.code){
        return <div></div>;
    }
    return(
        <Modal
            isOpen={isOpen}
            onRequestClose={() => close()}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0, 0.6)'
                },
            }}
            overlayClassName="fixed top-0 bottom-0 left-0 right-0 overflow-y-scroll"
            className="bg-white w-full max-w-4xl mx-auto my-20 shadow rounded-md p-4 focus:outline-none"
        >
            <Row titleRatio="1/2" title={t("Code")} content={appointment.code}></Row>
            <Row titleRatio="1/2" title={t("Amount")} content={appointment.amount}></Row>
            <Row titleRatio="1/2" title={t("Customer")} content={appointment.customer?.name}></Row>
            <Row titleRatio="1/2" title={t("Customer Phone")} content={appointment.customer?.phone}></Row>
            <Row titleRatio="1/2" title={t("Specialization")} content={appointment.doctor?.specialization?.title}></Row>
            <Row titleRatio="1/2" title={t("Clinic")} content={appointment.doctor?.clinic?.name}></Row>
            <Row titleRatio="1/2" title={t("Doctor")} content={appointment.doctor?.name}></Row>
            <Row titleRatio="1/2" title={t("Status")} content={appointment.status}></Row>
            <Row titleRatio="1/2" title={t("Date")} content={formateDate(appointment.start)}></Row>
            <Row titleRatio="1/2" title={t("Start Time")} content={formatTime(appointment.start)}></Row>
            <Row titleRatio="1/2" title={t("End Time")} content={formatTime(appointment.end)}></Row>
            <Row titleRatio="1/2" title={t("Actual Start Time")} content={formatTime(appointment.started_at)}></Row>
            <Row titleRatio="1/2" title={t("Actual End Time")} content={formatTime(appointment.ended_at)}></Row>
            <Row titleRatio="1/2" title={t("Join Time")} content={formatTime(appointment.customer_joined_at)}></Row>
            <Row titleRatio="1/2" title={t("Patient diagnosis")} content={appointment.patient_note}></Row>
            <Row titleRatio="1/2" title={t("Doctor notes")} content={appointment.doctor_note}></Row>

        </Modal>
    )
}
