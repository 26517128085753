import i18n from "i18next"

i18n.init({
    // we init with resources
    resources: {
        en: {
            translations: {
                Reservations: "Reservations",
                Employees: "Employees",
                Settings: "Settings",
                Search: "Search",
                "Export Reservations": "Export Reservations",
                "Export": "Export",
                "Code": "Code",
                "Service": "Service",
                "Customer": "Customer",
                "Quantity": "Quantity",
                "Employee": "Employee",
                "Amount": "Amount",
                "Status": "Status",
                "Reservation Time": "Reservation Time",
                "Actions": "Actions",
                "View": "View",
                "Reservation Code": "Code",
                "General Information": "General Information",
                "Category": "Category",
                "Gender": "Gender",
                "Address": "Address",
                "Notes": "Notes",
                "Medical Documents": "Medical Documents",
                "No Medical Documents": "No Medical Documents",
                "No Recordings": "No Recordings",
                "Recordings": "Recordings",
                "Results": "Results",
                "Result": "Result",
                "Delete": "Delete",
                "Download": "Download",
                "Done": "Done",
                "No Results Were Found": "No Results Were Found",
                "Add New Result": "Add New Result",
                "Are you sure you want to confirm?": "Are you sure you want to confirm?",
                "Reservation has been confirmed": "Reservation has been confirmed",
                "Are you sure you want to delete the result?": "Are you sure you want to delete the result?",
                "Female": "Female",
                "Male": "Male",
                "Cancel": "Cancel",
                "Categories": "Categories",
                "Working Days": "Working Days",
                "Block Time": "Block Time",
                "Services": "Services",
                "Save": "Save",
                "Search for a service": "Search for a service",
                "Empty": "Empty",
                "Deactivated": "Deactivated",
                "Active": "Active",
                "Activate": "Activate",
                "Deactivate": "Deactivate",
                "Add Employee": "Add Employee",
                "Time Duration": "Time Duration",
                "Minute": "Minute",
                "Password": "Password",
                "Login": "Login",
                "Phone Number": "Phone Number",
                "From": "From",
                "To": "To",
                "PM": "PM",
                "AM": "AM",
                "Add": "Add",
                "Add New Employee": "Add New Employee",
                "Arabic Name": "Arabic Name",
                "English Name": "English Name",
                "At least 1 minute": "At least 1 minute",
                "Should be less than 1440 minutes": "Should be less than 1440 minutes",
                "Name must be more than 3 charachters.": "Name must be more than 3 charachters.",
                "Title must be more than 2 charachters.": "Title must be more than 2 charachters.",
                "Minutes": "Minutes",
                "No Reservations Were Found.": "No Reservations Were Found.",
                "Are you sure?": "Are you sure?",
                "Are you sure you want to cancel the service?": "Are you sure you want to cancel the service?",
                "Reservation Title": "Reservation",
                "password field is required": "password field is required",
                "phone field is required": "phone field is required",
                "Employee has been deleted": "Employee has been deleted",
                "No Notifications": "No Notifications",
                "On": "On",
                "from": "from",
                "Clinics": "Clinics",
                "Specializations": "Specializations",
                "Logo": "Logo",
                "Arabic Title": "Arabic Title",
                "English Title": "English Title",
                "Duration": "Duration",
                "Edit": "Edit",
                "Image": "Image",
                "Choose Image": "Choose Image",
                "Add New Specialization": "Add New Specialization",
                "Add Specilization": "Add Specilization",
                "Invalid email address": "Invalid email address",
                "Drag marker to set location": "Drag marker to set location",
                "Email": "Email",
                "Phone": "Phone",
                "Name": "Name",
                "Experience": "Experience",
                "Doctor Type": "Doctor Type",
                "Created at": "Created at",
                "Arabic": "Arabic",
                "Specilization": "Specilization",
                "No selected tags": "No selected tags",
                "Tags": "Tags",
                "Languages": "Languages",
                "Years": "Years",
                "Experience years": "Experience years",
                "License": "License",
                "License must be at least 5 charachter.": "License must be at least 5 charachter.",
                "Phone number must be at least 10 digits.": "Phone number must be at least 10 digits.",
                "Both": "Both",
                "Visit": "Visit",
                "Virtual": "Virtual",
                "Should be less than 50": "Should be less than 50",
                "At least 0 years": "At least 0 years",
                "At least 0": "At least 0",
                "Title": "Title",
                "Title in English": "English Title",
                "Title in Arabic": "Arabic Title",
                "Doctors": "Doctors",
                "Add Doctor": "Add Doctor",
                "Add Clinic": "Add Clinic",
                "Add New Doctor": "Add New Doctor",
                "Specialization": "Specialization",
                "English About": "English About",
                "Arabic About": "Arabic About",
                "Add New Clinic": "Add New Clinic",
                "Location": "Location",
                "Arabic Tag": "Arabic Tag",
                "English Tag": "English Tag",
                "Create New Tag": "Create New Tag",
                "Commission": "Commission",
                "Name must be more than 2 charachters.": "Name must be more than 2 charachters",
                "Email field is required": "Email field is required",
                "Clinic": "Clinic",
                "Working Hours": "Working Hours",
                "Image is required": "Image is required",
                "Price": "Price",
                "Price in SAR": "Price in SAR",
                "Service Type": "Service Type",
                "Chat": "Chat",
                "Video": "Video",
                "Voice": "Voice",
                "Should be less than 100": "Should be less than 100",
                "Appointments": "Appointments",
                "Date": "Date",
                "Doctor": "Doctor",
                "Activated successfully": "Activated successfully",
                "Deactivated successfully": "Deactivated successfully",
                "Start can't be after end": "Start time can't be after end",
                "Start can't be equal to end": "Start time can't be equal to end",
                "Invalid time format": "Time format must be HH:MM",
                "Patient diagnosis": "Patient diagnosis",
                "Doctor notes": "Doctor notes",
                "Start Time": "Start Time",
                "End Time": "End Time",
                "Actual Start Time": "Actual Start Time",
                "Actual End Time": "Actual End Time",
                "Join Time": "Customer Join Time",
                "Edit time": "Edit time",
                "Time": "Time",
                "In past or invalid": "In past or invalid",
                "Rescheduled Successfully": "Rescheduled Successfully",
                "Change Status": "Update Status",
                "Status_Cancelled" : "Cancelled",
                "Status_Done" : "Done",
                "action_done" : "Done",
                "something_went_wrong" : "Something went wrong",
                "Customer Phone" : "Customer Phone",
            }
        },
        ar: {
            translations: {
                Reservations: "الحجوزات",
                Employees: "الموظفين",
                Settings: "الإعدادات",
                Search: "بحث",
                "Export Reservations": "تصدير الحجوزات",
                "Export": "تصدير",
                "Code": "رقم الحجز",
                "Service": "الخدمة",
                "Customer": "العميل",
                "Quantity": "الكمية",
                "Employee": "الموظف",
                "Amount": "المبلغ",
                "Status": "الحالة",
                "Reservation Time": "وقت الحجز",
                "Actions": "الإجراءات",
                "View": "عرض",
                "Reservation Code": "رقم الحجز",
                "General Information": "معلومات عامة",
                "Category": "الصنف",
                "Gender": "النوع",
                "Address": "العنوان",
                "Notes": "ملاحظات",
                "Medical Documents": "مستندات طبية",
                "No Medical Documents": "لا يوجد مستندات طبية",
                "No Recordings": "لا يوجد تسجيلات صوتية",
                "Recordings": "تسجيلات صوتية",
                "Results": "النتائج",
                "Result": "نتيجة",
                "Delete": "حذف",
                "Download": "تحميل",
                "Done": "انهاء",
                "No Results Were Found": "لا يوجد نتائج",
                "Add New Result": "إضافة نتيجة جديدة",
                "Are you sure you want to confirm?": "هل أنت متأكد من انهاء الحجز؟",
                "Reservation has been confirmed": "تم انهاء الحجز",
                "Are you sure you want to delete the result?": "هل أنت متأكد من حذف النتيجة؟",
                "Female": "أنثى",
                "Male": "ذكر",
                "Cancel": "الغاء",
                "Save": "حفظ",
                "Categories": "التصنيفات",
                "Working Days": "أيام العمل",
                "Block Time": "أوقات العطل",
                "Services": "الخدمات",
                "Search for a service": "ابحث عن خدمة",
                "Empty": "لا يوجد",
                "Deactivated": "غير نشط",
                "Active": "نشط",
                "Activate": "تنشيط",
                "Deactivate": "تعطيل",
                "Add Employee": "إضافة موظف",
                "Time Duration": "المدة الزمنية",
                "Minute": "دقيقة",
                "Password": "كلمة المرور",
                "Login": "تسجيل دخول",
                "Phone Number": "رقم الهاتف",
                "From": "من",
                "To": "إلى",
                "Add": "إضافة",
                "Add New Employee": "إضافة موظف جديد",
                "Arabic Name": "الاسم بالعربية",
                "English Name": "الاسم بالإنجليزية",
                "At least 1 minute": "دقيقة واحدة أو أكثر",
                "Should be less than 1440 minutes": "يجب أن تكون أقل من 1440 دقيقة",
                "Name must be more than 3 charachters.": "الاسم يجب أن يكون أكثر من 3 حروف",
                "Minutes": "دقائق",
                "No Reservations Were Found.": "لا يوجد حجوزات",
                "Are you sure?": "هل أنت متأكد؟",
                "Are you sure you want to cancel the service?": "هل أنت متأكد من الغاء الخدمة؟",
                "Reservation Title": "حجز",
                "password field is required": "أدخل كلمة المرور",
                "phone field is required": "أدخل رقم الهاتف",
                "Employee has been deleted": "تم حذف الموظف",
                "No Notifications": "لا يوجد إشعارات",
                "On": "في",
                "from": "من",
                "Clinics": "العيادات",
                "Specializations": "التخصصات",
                "Logo": "الشعار",
                "Arabic Title": "العنوان بالعربيلا",
                "English Title": "العنوان بالإنجليزيلا",
                "Duration": "الفترة الزمنية",
                "Edit": "تعديل",
                "Image": "صورة",
                "Choose Image": "اختار صورة",
                "Add New Specialization": "إضافة تخصص جديد",
                "Add Specilization": "أضف تخصص",
                "Title must be more than 2 charachters.": "اللقب يجب أن يكون أكثر من حرفين",
                "Invalid email address": "البريد الإلكتروني خاطئ",
                "Email": "البريد الإلكتروني",
                "Phone": "الهاتف",
                "Name": "الاسم",
                "Experience": "الخبرة",
                "Doctor Type": "النوع",
                "Created at": "تاريخ الانشاء",
                "Arabic": "عربي",
                "Specilization": "تخصص",
                "No selected tags": "لا يوجد وسوم مختارة",
                "Tags": "الوسوم",
                "Languages": "اللغات",
                "Years": "سنوات",
                "Experience years": "سنوات الخبرة",
                "License": "رقم الرخصة",
                "License must be at least 5 charachter.": "رقم الرخصة يجب أن يكون أكثر من ٥ حروف.",
                "Phone number must be at least 10 digits.": "رقم الهاتف يجب أن يكون ١٠ أرقام أو أكثر",
                "Both": "زياة وافتراضي",
                "Visit": "زيارة",
                "Virtual": "افتراضي",
                "Should be less than 50": "يجب أن يكون أقل من ٥٠",
                "At least 0 years": "على الأقل ٠ سنة",
                "At least 0": "يجب أن لا يكون أقل من صفر",
                "Title": "اللقب",
                "Title in English": "اللقب بالإنجليزية",
                "Title in Arabic": "اللقب بالعربية",
                "Doctors": "الدكاترة",
                "Add Doctor": "إضافة دكتور",
                "Add Clinic": "إضافة عيادة",
                "Add New Doctor": "إضافة دكتور جديد",
                "Specialization": "التخصص",
                "English About": "عن الدكتور (بالإنجليزي)",
                "Arabic About": "عن الدكتور (بالعربي)",
                "Add New Clinic": "إضافة عيادة جديدة",
                "Location": "الموقع",
                "Arabic Tag": "الوسم بالعربية",
                "English Tag": "الوسم بالإنجليزية",
                "Create New Tag": "أنشئ وسم جديد",
                "Commission": "العمولة",
                "Name must be more than 2 charachters.": "الاسم يجب أن يكون أكثر من حرفين",
                "Email field is required": "البريد الإلكتروني مطلوب",
                "Clinic": "العيادة",
                "Working Hours": "ساعات العمل",
                "Image is required": "الصورة مطلوبة",
                "Price": "السعر",
                "Price in SAR": "السعر بالريال السعودي",
                "Service Type": "نوع الخدمة",
                "Chat": "محادثة كتابية",
                "Video": "مكالمة فيديو",
                "Voice": "مكالمة صوتية",
                "Should be less than 100": "يجب أن يكون أقل من 100",
                "Appointments": "المواعيد",
                "Date": "التاريخ",
                "Doctor": "الطبيب",
                "Drag marker to set location": "اسجب العلامة لضبط الموقع",
                "Activated successfully": "تم التفعيل بنجاح",
                "Deactivated successfully": "تم التعطيل بنجاح",
                "Start can't be after end": "لا يمكن أن يكون وقت البداية بعد النهاية",
                "Start can't be equal to end": "لا يمكن أن يكون وقت البداية مثل وقت النهاية",
                "Invalid time format": "صيغة الوقت يجبب أن تكون HH:MM",
                "Patient diagnosis": "التشخيص الخاص بالمريض",
                "Doctor notes": "ملاحظات الطبيب",
                "Start Time": "وقت البداية",
                "End Time": "وقت النهاية",
                "Actual Start Time": "وقت البداية الفعلي",
                "Actual End Time": "وقت النهاية الفعلي",
                "Join Time": "وقت التحاق العميل",
                "Edit time": "تعديل الوقت",
                "Time": "الوقت",
                "In past or invalid": "موعد خاطيء أو في الماضي",
                "Rescheduled Successfully": "تم تغيير المعاد بنجاح",
                "Status_Cancelled" : "ملغي",
                "Status_Done" : "منتهي",
                "action_done" : "تم",
                "something_went_wrong" : "حدث خطأ ما",
                "Customer Phone" : "هاتف العميل",
            }
        },
    },

    fallbackLng: "ar",

    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    },
});

export default i18n
