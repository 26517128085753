import React from 'react'
import {useTranslation} from 'react-i18next'


export default function SearchResultSection({search, pagination}) {
    const { t } = useTranslation()
    return search.length > 0 && <div className="flex items-center flex-col py-5 md:py-10">
        <h2 className="text-2xl font-bold">{search}</h2>
        <p className="text-normal text-gray-500">{pagination.total} {t("Result")} </p>
    </div>
}