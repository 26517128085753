import React from 'react'
import {useRef, useState} from 'react'
import {useForm} from "react-hook-form"

import Button from '@components/Button'
import ImagePlacholder from '../../assets/imgs/image-placholder.png'
import ApiService from '@services/api.service'
import {useTranslation} from 'react-i18next'

export default function SpecializationForm({specialization = {}, onSuccess, onCancel}) {
    const {register, handleSubmit, errors, setValue, getValues} = useForm()
    const onSubmit = (data) => specialization.id ? updateSpecialization(data) : addSpecialization(data)
    const onError = (errors, e) => console.log(errors, e)
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [serverErrors, setServerErrors] = useState([])
    const imageTag = useRef(null)

    async function addSpecialization({image, title_ar, title_en, duration, commission}) {
        try {

            const formData = new FormData()
            formData.set('title_ar', title_ar)
            formData.set('title_en', title_en)
            formData.set('duration', duration)
            formData.set('commission', commission)
            if (image && image.length == 0) {
                setServerErrors([ t("Image is required") ])
                return
            } else {
                formData.set('image', image[0])
            }

            image && image.length > 0 && formData.set('image', image[0])

            setIsLoading(true)
            await ApiService.post(`/api/specializations`, formData)
            setIsLoading(false)
            onSuccess()
        } catch(e) {
            setServerErrors([e.toastMessage])
            setIsLoading(false)
        }
    }

    async function updateSpecialization({image, title_ar, title_en, duration, commission}) {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.set('_method', 'put')
            formData.set('title_ar', title_ar)
            formData.set('title_en', title_en)
            formData.set('duration', duration)
            formData.set('commission', commission)

            image && image.length > 0 && formData.set('image', image[0])

            await ApiService.post(`/api/specializations/${specialization.id}`, formData)
            setIsLoading(false)
            onSuccess()
        } catch(e) {
            setServerErrors([e.toastMessage])
            setIsLoading(false)
        }
    }

    function onImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader()

            reader.onload = function (e) {
                imageTag.current.src = e.target.result
            }

            reader.readAsDataURL(e.target.files[0])
        }
    }

    return(
        <div>
            <h4 className="pb-4 border-b border-gray-50 w-full text-center">
                { specialization.id ? t("Edit Specialization") : t("Add New Specialization") }
            </h4>
            <div className="py-4">
                <div className="flex mx-auto">
                    <label htmlFor="image" className="cursor-pointer inline-block mx-auto text-center text-gray-500 text-sm">
                        <img src={specialization.id && specialization.image ? specialization.image : ImagePlacholder} ref={imageTag} className={`w-24 h-24 mx-auto object-cover object-center rounded-full border ${errors.image ? 'border-red-600' : 'border-gray-75' }`} alt="image"></img>
                        <span className={`mt-2 ${errors.image ? 'text-red-600' : '' }`}>{t("Choose Image")}</span>
                    </label>
                    <input onChange={onImageChange} type="file" id="image" name="image" accept="image/*" className="hidden" ref={register({required: false})}></input>
                </div>
                <div className="flex mt-4">
                    <div className="w-1/2 pe-4">
                        <label className="c-label">{t("Arabic Title")} <span className="text-red-600 text-sm ms-2">{ errors.title_ar?.message ?  (`${errors.title_ar.message}`) : ''  }</span></label>
                        <input defaultValue= {specialization.title_ar} placeholder="عنوان بالعربية" name="title_ar" type="text" className={`c-input mt-1 ${errors.title_ar ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                            value: 3,
                            message: t("Title must be more than 2 charachters.")
                        }})}></input>
                    </div>

                    <div className="w-1/2">
                        <label className="c-label">{t("English Title")} <span className="text-red-600 text-sm ms-2">{ errors.title_en?.message ?  (`${errors.title_en.message}`) : ''  }</span></label>
                        <input defaultValue= {specialization.title_en} placeholder="English title" name="title_en" type="text" className={`c-input mt-1 ${errors.title_en ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                            value: 3,
                            message: t("Title must be more than 2 charachters.")
                        }})}></input>
                    </div>
                </div>
                <div className="flex mt-4">
                    <div className="w-1/2 pe-4">
                        <label className="c-label">{t("Commission")} <span className="text-red-600 text-sm ms-2">{ errors.commission?.message ?  (`${errors.commission.message}`) : ''  }</span></label>
                        <input defaultValue= {specialization.commission} placeholder="%" name="commission" type="number" min="0" max="100" className={`c-input mt-1 ${errors.commission ? 'c-input--has-error' : ''}`}
                        ref={register({
                            required: true,
                            min: {
                                value: 0,
                                message: t("At least 0")
                            },
                            max: {
                                value: 100,
                                message: t("Should be less than 100")
                            }
                        })}></input>
                    </div>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("Duration")} <span className="text-red-600 text-sm ms-2">{ errors.about_en?.message ?  (`${errors.about_en.message}`) : ''  }</span></label>
                    {[10,15,30,45,60].map(duration =>
                    <div>
                        <input className="me-2" id={`duration-${duration}`} name="duration" type="radio" value={duration} ref={register} defaultChecked={specialization.id ? specialization.duration == duration : duration == '15'}></input>
                        <label className="select-none" htmlFor={`duration-${duration}`}>{duration} {t("Minutes")}</label>
                    </div>)}
                </div>

                <div className='mt-4'>
                    <ul className="text-red-700 font-bold list-disc ms-4">
                        {serverErrors.map(error => <li>{error}</li>)}
                    </ul>
                </div>
            </div>
            <div className="flex justify-center relative border-t border-gray-50 pt-4">
                <Button title={t("Save")} loading={isLoading} onClick={handleSubmit(onSubmit, onError)}></Button>
                <button className="px-2 py-1 absolute left-0" onClick={() => onCancel()}>{t("Cancel")}</button>
            </div>
        </div>
    )
}
