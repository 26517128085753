import axios from "axios"
import {getToken} from './jwt.service'
import useLocale from '../hooks/useLocale'
import {get, first, keys} from 'lodash'
/**
 * Service to call HTTP request via Axios
 */

const locale = useLocale() == 'en' ? 'en' : 'ar'

const ApiService = window.Api = {
    init() {
        // axios.defaults.withCredentials = true
        axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
        axios.defaults.headers = { 
            'content-type': 'application/json',
            'accept': 'application/json',
            'accept-language': locale
        }

        axios.defaults.headers.common = {"Authorization": `Bearer ${ getToken() }`}

        axios.interceptors.response.use(
            response => response,  
            error => {
                error.toastMessage = 'Something went wrong'

                if (error.response && error.response.status == 401) {
                    error.toastMessage = error.response.data.message
                }
                
                if (error.response && error.response.status == 404) {
                    error.toastMessage = ''
                }

                if (error.response && error.response.status == 422) {
                    error.toastMessage = get(error.response.data.errors, first(keys(error.response.data.errors)))
                }
                
                if (error.response && error.response.status == 429) {
                    error.toastMessage = 'Too many requests.'
                }

                if (error.response && error.response.status == 500) {
                    error.toastMessage = 'Internal server error.'
                }
                
                if (! error.response) {
                    error.toastMessage = error.message
                }

                return Promise.reject(error)
            }
        )
    },

    query(resource, params) {
        return axios.get(resource, params)
    },

    /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
    get(resource, slug = "", params) {
        return axios.get(`${resource}/${slug}`, params)
    },

    /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
    post(resource, params, options = {}) {
        return axios.post(`${resource}`, params, options)
    },
  
    /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
    update(resource, slug, params) {
        return axios.put(`${resource}/${slug}`, params)
    },

    /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
    put(resource, params) {
        return axios.put(`${resource}`, params)
    },

    /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
    delete(resource) {
        return axios.delete(resource)
    }
}

export default ApiService