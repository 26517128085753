import React from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'


export default function SearchInput({initialSearch, onSearch = () => {}}) {
    const [search, setSearch] = useState(initialSearch || '')
    const {t} = useTranslation()
    
    function handleSearchChange(e) {
        setSearch(e.target.value)
    }

    function onKeyDown(e) {
        if (e.key === 'Enter') {
            onSearch(e.target.value)
        }
    }

    function clearSearch() {
        onSearch('')
    }

    return (
        <div className="flex items-center relative">
            <span className="absolute  ltr:left-0 ms-3 text-gray-300">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </span>
                {search.length > 0 ? <button className="absolute ltr:right-0 rtl:left-0 me-3 text-primary" onClick={clearSearch}>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg>
            </button> : ''}
            <input className="c-input ps-10 placeholder-gray-300" placeholder={t("Search") + `...`} value={search} onBlur={() => setSearch(search)} onChange={handleSearchChange} onKeyDown={onKeyDown}></input>
        </div>
    )
}
