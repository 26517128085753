import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'

import Layout from '@pages/Layout'
import useUser from '@hooks/useUser'
import dayOfWeek from '@utils/day-of-week'
import getHour from '@utils/get-hour'
import ApiService from '@services/api.service'
import BlockTimeTable from '@components/Providers/BlockTimeTable'
import useLocale from '@hooks/useLocale'

import Loader from '@components/Loader'
import Row from '@components/Row'


function Services({services, loading = false}) {
    const {t} = useTranslation()
    const {addToast} = useToasts()

    const [search, setSearch] = useState('')
    const [filteredServices, setFilteredServices] = useState(services)
    

    const searchFilter = service => service.name_ar?.toLowerCase().includes(search.toLowerCase()) || service.name_en?.toLowerCase().includes(search.toLowerCase())
    
    useEffect(() => {
        setFilteredServices(services.filter(searchFilter))
    }, [search])

    return(
        <>
            <div className="px-2 py-1">
                <input className="c-input" placeholder={t("Search for a service")} onChange={(e) => setSearch(e.target.value)}></input>
            </div>

            {filteredServices.length == 0 && !loading ? <p className="text-center py-10 text-gray-300 font-light">{t("No Results Were Found")}</p> : ''}
            
            {loading  && <Loader /> }

            <ul className="flex flex-wrap">
                {
                    filteredServices.map(service => {
                        return <ServiceCard key={`service-${service.id}`} initialService={service} />
                    })
                }
            </ul>
        </>
    )
}
function ServiceCard({initialService}) {
    const [service, setService] = useState(initialService)
    const [truncate, setTruncate] = useState(true)
    const [isEditing, setIsEditing] = useState(false)
    const { t } = useTranslation()
    const locale = useLocale()
    const {addToast} = useToasts()

    function toggleService(event, service) {
        if (event.target.checked) {
            updatePrice(service.id, service.price ?? 1).then(res => {
                setService({...service, selected: true, price: 1})
            })
        } else {
            if (! window.confirm(t("Are you sure you want to cancel the service?"))) return
            detachServices([service.id])
            setService({...service, selected: false})
        }
    }

    async function updatePrice(serviceId, price) {
        try {
            await ApiService.post(`/api/providers/service-price`, {
                serviceId: serviceId,
                price: price
            })
            
            setService({...service, price: price})
            setIsEditing(false)
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    async function detachServices(services) {
        try {
            await ApiService.post(`/api/providers/services-detach`, {
                servicesToDetach: services
            })
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    return(
        <li className="c-service w-full md:w-1/3 px-2 py-1" key={service.id}>
            <span className="w-full flex justify-between p-2 border border-gray-75 rounded">
                <label className="w-full flex flex-1 items-center cursor-pointer font-medium" htmlFor={service.id}>
                    { service.selected && <svg className={`w-6 h-6 me-2 text-green-600 hover:text-green-700 flex-shrink-0`} viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg> }
                    { !service.selected && <svg className={`w-6 h-6 me-2 text-red-600 hover:text-red-700 flex-shrink-0`} viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg> }
                    <input className="me-2 hidden" type="checkbox" id={service.id} defaultChecked={service.selected} onChange={(e) => toggleService(e, service)} />
                </label>
                
                <span onClick={() => setTruncate(!truncate)} className={`w-full ${truncate ? 'truncate' : ''}`}>{ service.name }</span>
                                
                <span className={`flex-center flex-shrink-0 text-center ${service.selected ? 'flex' : 'hidden'}`}>
                    { 
                    isEditing ? 
                        <>
                            <input className="c-input text-center w-24 p-0 mx-1 bg-white" type="number" defaultValue={service.price} placeholder="1" step="0.1" min="0" onChange={(e) => {setService({...service, tempPrice: e.target.value})}}></input>
                            <button className="text-xs text-green-600 me-2" onClick={()=> updatePrice(service.id, service.tempPrice)}>{t("Save")}</button>
                            <button className="text-xs text-red-600" onClick={() => setIsEditing(false)}>{t("Cancel")}</button>
                        </>
                        :
                        <>
                            <span className="text-center bg-white">{service.price}</span>
                            <span className="mx-2 text-xs">{ locale == 'en' ? 'SAR' : 'ريال' }</span>
                            <button className="text-xs text-primary border-s ps-2 border-gray-100" onClick={() => {setIsEditing(true); setService({...service, tempPrice: service.price}) }}>
                                <svg className="w-5 h-5 text-primary" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" /></svg>
                            </button>
                        </>
                    }
                </span>
            </span>
        </li>
    )
}


export default function Settings() {
    const provider = useUser()
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)
    const {addToast} = useToasts()

    const { t } = useTranslation()
    useEffect(() => {
        fetchServices()
    }, [])

    async function fetchServices() {
        try {
            setLoading(true)
            const response = await ApiService.get(`/api/providers/allowed-services?all=1`)
            setServices(response.data.data)
            setLoading(false)
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
            setLoading(false)
        }
    }


    function WorkingDaysTable({provider}) {
        return (
            <table className="table max-w-2xl border border-gray-100 mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th scope="col" className="text-center">{t("From")}</th>
                        <th scope="col" className="text-center">{t("To")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        provider?.workingDays.map(day => 
                            day.workinHours.map((hour, index) => {
                                return (
                                    <tr key={`hour-${hour.id}`}>
                                        { index == 0 && <th className="border-l border-gray-100" scope="row" rowSpan={index == 0 && day.workinHours.length > 0 ? day.workinHours.length : 1}>{ dayOfWeek(day.day) }</th> }
                                        <td className="font-medium text-center">{ getHour(hour.start) }</td>
                                        <td className="font-medium text-center">{ getHour(hour.end) }</td>
                                    </tr>
                                )
                            })
                        )
                    }
                </tbody>
            </table>
        )
    }

    
    function Content() {
        return (
            <div>
                <div className="w-full flex-center">
                    <img className="w-24 h-24 mx-auto object-cover object-center rounded-full border border-gray-75" src={provider?.logo} alt="Logo"></img>
                </div>
                <div>
                    <Row title={t("Address")} content={provider?.address}></Row>

                    <Row title={t("Categories")} content={
                        <ul className="flex flex-wrap">
                            {
                                provider?.categories?.map(category => {
                                    return (
                                        <li className="p-1" key={`category-${category.id}`}>
                                            <span className="w-full block px-2 py-1 border border-gray-75 rounded">{ category.name }</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }/>

                    <Row title={t("Working Days")} content={<WorkingDaysTable provider={provider}></WorkingDaysTable>}/>
                    <Row title={t("Block Time")} content={<BlockTimeTable intialProvider={provider}></BlockTimeTable>} />

                    <Row title={t("Services")} content={<Services services={services} loading={loading}></Services>}/>
                </div>
            </div>
        )
    }

    return (
        <Layout content={<Content />} title={t("Settings")}>

        </Layout>        
    )
}