import useLocale from '../hooks/useLocale'

const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    // hour: 'numeric', minute: 'numeric',
    hour12: true,
    timeZone: 'Asia/Riyadh'
}

export default function(unixTimestamp, locale = null, formatToParts = false) {
    if(!unixTimestamp){
        return ''
    }
    try {
        let usedLcale = locale ? locale : useLocale() == 'en' ? 'en-GB' : 'ar-EG'
        if(formatToParts){
            return new Intl.DateTimeFormat(usedLcale, options).formatToParts(new Date(unixTimestamp * 1000))
        }
        return new Intl.DateTimeFormat(usedLcale, options).format(new Date(unixTimestamp * 1000))
    } catch(e) {
        console.error(e)
        return ''
    }
}
