import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Loader from '@components/Loader'
import Button from '@components/Button'
import Status from '@components/Status'
import Pagination from '@components/Pagination'
import EmptyResult from '@components/EmptyResult'
import SearchResultSection from '@components/SearchResultSection'

import Layout from '@pages/Layout'
import store from '../store'
import ApiService from '../services/api.service'
import {useHistory, useLocation} from 'react-router-dom'

import formateDate from '../utils/format-date'
import queryString from 'query-string'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'
import AppointmentModal from "../Doctors/AppointmentModal";
import RescheduleAppointmentModal from "../Doctors/RescheduleAppointmentModal";
import axios from 'axios';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function Appointments() {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [rescheduleModalIsOpen, setRescheduleModalIsOPen] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const urlQuery = useQuery()
    const appointments = useSelector(state => state.appointments.appointments)
    const page = useSelector(state => state.appointments.page)
    const pagination = useSelector(state => state.appointments.pagination)
    const [hasMounted, setHasMounted] = useState(false)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const {t} = useTranslation()
    const {addToast} = useToasts()

    useEffect(() => {
        const initialPage = urlQuery.get('page') || ''

        if (!hasMounted) {
            initialPage && store.dispatch({type: 'APPOINTMENTS:SET_PAGE', page: initialPage})
            setHasMounted(true)
            fetchAppointments(initialPage)
            return
        }

        fetchAppointments(initialPage)
        setHasMounted(true)
    }, [page])

    function setPage(pageNumber) {
        store.dispatch({
            type: 'APPOINTMENTS:SET_PAGE',
            page: pageNumber
        })

        let params = queryString.parse(urlQuery.toString())
        params = {...params, page: pageNumber}
        history.push({pathname: `/appointments`, search: queryString.stringify(params)})
    }

    async function fetchAppointments(page) {
        try {
            store.dispatch({
                type: 'SET_APPOINTMENTS',
                appointments: []
            })

            setLoading(true)

            const response = await ApiService.get(`api/admin/appointments?&page=${page}`)
            store.dispatch({
                type: 'APPOINTMENTS:SET_PAGINATION',
                pagination: response['data']['pagination']
            })

            store.dispatch({
                type: 'SET_APPOINTMENTS',
                appointments: response['data']['data']
            })

        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setLoading(false)
        }
    }

    function AppointmentsTable({appointments}) {
        return (
            <table className="w-full border border-gray-50 text-sm md:text-base" style={{minWidth: '1200px'}}>
                <thead className="bg-gray-50">
                <tr className="h-12 text-gray-600 border border-b-2 border-gray-75 text-start">
                    <th className="px-1 sm:px-4 w-12 border-l border-gray-75 text-center">#</th>
                    <th className="px-1 w-20 sm:px-4">{t("Code")}</th>
                    <th className="px-1 w-64 sm:px-4">{t("Amount")}</th>
                    <th className="px-1 w-64 sm:px-4">{t("Customer")}</th>
                    <th className="px-1 w-64 sm:px-4">{t("Customer Phone")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Specialization")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Clinic")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Doctor")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Status")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Date")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Created at")}</th>
                    <th className="px-1 w-32 sm:px-4">{t("Actions")}</th>
                </tr>
                </thead>
                <tbody>
                {appointments.map((appointment, index) => {
                    return (
                        <tr className="hover:bg-gray-25 cursor-pointer" key={index.toString()}
                            onClick={e => {
                                e.stopPropagation()
                                setSelectedAppointment(appointment)
                                setModalIsOpen(true)
                            }}
                        >
                            <td className="px-2 sm:px-4 py-3 border-l border-gray-50 text-center">{ (index + 1).toString() }</td>
                            <td className="px-2 sm:px-4 py-3 w-20">{appointment.code}</td>
                            <td className="px-2 sm:px-4 py-3 w-20 md:w-40">{appointment.amount}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.customer?.name}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.customer?.phone}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.doctor?.specialization?.title}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.doctor?.clinic?.name}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.doctor?.name}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.status}</td>
                            <td className="px-2 sm:px-4 py-3">{formateDate(appointment.start)}</td>
                            <td className="px-2 sm:px-4 py-3">{appointment.created_at}</td>
                            <td className="px-2 sm:px-4 py-3 cursor-default" onClick={e =>
                                e.stopPropagation()
                            }>
                                <button className="px-2 py-1 my-1 mx-1 text-xs border border-primary text-primary rounded-md"
                                        onClick={ e => {
                                            e.stopPropagation()
                                            setSelectedAppointment(appointment)
                                            setRescheduleModalIsOPen(true)
                                        }}
                                >
                                    {t("Edit time")}
                                </button>

                                <ChangeStatusButtons appointment={appointment}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
    function ChangeStatusButtons({appointment}) {
        if(appointment.status_id != 2){
            return <span></span>;
        }
        return (
            <>
            <button className="px-2 py-1 my-1 mx-1 text-xs border border-primary text-primary rounded-md"
                    onClick={ e => {
                        e.stopPropagation()
                        if(!window.confirm('Are you sure')){
                            return;
                        }
                        axios.patch(`/api/appointments/${appointment.id}/status/cancelled`)
                            .then(response => {
                                appointment.status = response.data.data.status
                                appointment.status_id = response.data.data.status_id
                                addToast(t('action_done'), {appearance: 'success'})
                            }).catch(() => addToast(t('something_went_wrong'), {appearance: 'error'}))
                    }}
            >
                {t("Status_Cancelled")}
            </button>

            <button className="px-2 py-1 my-1 mx-1 text-xs border border-primary text-primary rounded-md"
                    onClick={ e => {
                        e.stopPropagation()
                        if(!window.confirm('Are you sure')){
                            return;
                        }
                        axios.patch(`/api/appointments/${appointment.id}/status/done`)
                            .then(response => {
                                appointment = response.data.data
                                addToast(t('action_done'), {appearance: 'success'})
                            }).catch(() => addToast(t('something_went_wrong'), {appearance: 'error'}))
                    }}
            >
                {t("Status_Done")}
            </button>
            </>
        )
    }
    function Content() {
        return (
            <div className="mx-auto">
                <AppointmentModal isOpen={modalIsOpen} close={() => setModalIsOpen(false)}  appointment={selectedAppointment}/>
                <RescheduleAppointmentModal isOpen={rescheduleModalIsOpen} close={() => setRescheduleModalIsOPen(false)} selectedAppointment={selectedAppointment}/>
                <div className="overflow-x-scroll h-full">
                    <AppointmentsTable appointments={appointments} />

                    { !loading && appointments.length > 0 && <Pagination total={pagination.totalPages} current={pagination.currentPage} onClick={(i) => setPage(i)} /> }

                    { loading && <Loader /> }

                    { !loading && appointments.length === 0 && <EmptyResult content={ t("No Appointments Were Found.") } />}

                </div>
            </div>
        )
    }

    return (
        <Layout key="all-appointments-layout" content={<Content key="content" />} title={t("Appointments")}></Layout>
    )
}
