import {combineReducers} from 'redux'
import loggedReducer from './isLogged'
import userReducer from './user'
import providerReducer from './provider'
import reservationsReducer from './reservations'
import employeesReducer from './employees'
import specializationsReducer from './specializations'
import clinicsReducer from './clinics'
import appointmentsReducer from './appointments'

export default combineReducers({
    logged: loggedReducer,
    provider: providerReducer,
    user: userReducer,
    employees: employeesReducer,
    clinics: clinicsReducer,
    specializations: specializationsReducer,
    reservations: reservationsReducer,
    appointments: appointmentsReducer,
})


