import React from 'react'
import {useRef, useState, useEffect} from 'react'
import {useForm} from "react-hook-form"

import Button from '@components/Button'
import makeDoctor from '@models/makeDoctor'
import SearchInput from '@components/SearchInput'
import ImagePlacholder from '../../assets/imgs/image-placholder.png'
import ApiService from '@services/api.service'
import useUser from '../../hooks/useUser'
import {useTranslation} from 'react-i18next'

function InputForm({label, error, name, type="text", placeholder, register, inputOptions = {}, defaultValue, ...rest}) {
    return (
        <div className="mt-4">
            <label className="c-label">
                {label} { error && <span className="text-red-600 text-sm ms-2">{ error }</span>}
            </label>
            <input
                defaultValue={defaultValue}
                name={name}
                type={type}
                placeholder={placeholder}
                className={`c-input mt-1 ${error ? 'c-input--has-error' : ''}`}
                ref={register}
                {...inputOptions}
            ></input>
        </div>
    )
}

function Tags({
    onSelect = () => {},
    onDeselect = () => {},
    initialSelectedTags = [],
}) {
    const {register, handleSubmit, errors, setValue, getValues} = useForm()

    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState(initialSelectedTags)
    const {t} = useTranslation()
    const onSubmit = (data) => addTag(data)

    useEffect(() => {
        fetchTags()
    }, [])

    async function fetchTags() {
        try {
            const response = await ApiService.get('/api/tags')
            setTags(response['data']['data'])
        } catch(e) {
            alert('Could not fetch the tags.')
        }
    }

    async function addTag({name_ar, name_en}) {
        try {
            const response = await ApiService.post('/api/tags', {name_ar, name_en})
            const newTag = response.data.data
            setTags([...tags, newTag])
            selectTag(newTag)
            setValue('name_ar', '')
            setValue('name_en', '')
        } catch(e) {
            alert('Couldn\'t add tag')
        }
    }

    function selectTag(tag) {
        onSelect(tag.id)
        setSelectedTags([...selectedTags, tag])
    }

    function removeTag(tag) {
        onDeselect(tag.id)
        setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
    }

    return (
        <div>
            <div className="flex flex-wrap">
                {
                    selectedTags.map((tag) => {
                        return <button className="border border-gray-75 flex items-center me-1 mt-1 p-1 px-2 rounded" onClick={() => removeTag(tag)}>
                            <svg className="border-e border-gray-100  pe-2 text-gray-400 h-4 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                            {`${tag.name_en} - ${tag.name_ar}`}
                        </button>
                    })
                }
            </div>
            { selectedTags.length == 0 && <div className="text-gray-500"> {t("No selected tags")}. </div> }
            <div className="bg-gray-50 shadow-inner mt-4 border border-gray-50  text-sm">
                <div className="h-12 px-2 flex items-center border-b border-gray-75 bg-gray-25">
                    <div className="me-2">
                        <input className='c-input bg-white p-1' id="name_ar" name="name_ar" type="text" placeholder={t("Arabic Tag")} ref={register({required: true})}></input>
                    </div>
                    <div className="me-2">
                        <input className='c-input bg-white p-1' id="name_ae" name="name_en" type="text" placeholder={t("English Tag")} ref={register({required: true})}></input>
                    </div>
                    <button onClick={handleSubmit(onSubmit)} className="flex items-center text-blue-500 ms-auto">
                        <svg viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" /></svg>
                        {t("Create New Tag")}
                    </button>
                </div>
                <div className="flex flex-wrap overflow-y-scroll mt-2" style={{maxHeight: '150px'}}>
                    {
                        tags.filter(tag => {
                            return !selectedTags.map( t => t.id).includes(tag.id)
                        }).map(tag => {
                            return (
                                <div className="ms-1 mt-1">
                                    <button className="px-2 py-1 border border-gray-75 bg-white cursor-pointer rounded-sm hover:bg-gray-25" id={tag.id} type="checkbox" name="tags" value={tag.id} onClick={() => selectTag(tag)}>{ `${tag.name_en} - ${tag.name_ar}` }</button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default function DoctorForm({clinic = {}, doctor = {}, onSuccess, onCancel}) {
    const {register, handleSubmit, errors, setValue, getValues} = useForm()
    const onSubmit = (data) => {
        const doctorData = makeDoctor({...doctor, ...data})
        doctorData.id ? updateDoctor(doctorData) : addDoctor(doctorData)
    }
    const onError = (errors, e) => console.log(errors, e)
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [selectedTags, setSelectedTags] = useState(doctor.tags ? doctor.tags.map(t => t.id) : [])
    const [serverErrors, setServerErrors] = useState([])
    const [specializations, setSpecializations] = useState([])
    const imageTag = useRef(null)

    useEffect(() => {
        fetchSpecializations()
    }, [])

    async function addDoctor(doctor) {
        try {
            const formData = new FormData()
            formData.set('title_en', doctor.title_en)
            formData.set('title_ar', doctor.title_ar)
            formData.set('name_en', doctor.name_en)
            formData.set('name_ar', doctor.name_ar)
            formData.set('phone', doctor.phone)
            formData.set('price', doctor.price)
            formData.set('email', doctor.email)
            formData.set('about_ar', doctor.about_ar)
            formData.set('about_en', doctor.about_en)
            formData.set('specialization_id', doctor.specialization_id)
            formData.set('clinic_id', clinic.id)
            formData.set('experience', doctor.experience)
            formData.set('type', doctor.type)
            formData.set('license', doctor.license)
            doctor.languages.map((language, index) => formData.set(`languages[${index}]`, language))
            doctor.services.map((service, index) => formData.set(`services[${index}]`, service))
            selectedTags.map((tag, index) => formData.set(`tags[${index}]`, tag))

            if (doctor.image && doctor.image.length == 0) {
                setServerErrors([ t("Image is required") ])
                return
            } else {
                formData.set('image', doctor.image[0])
            }


            setIsLoading(true)
            await ApiService.post(`/api/doctors`, formData)
            setIsLoading(false)
            onSuccess()
        } catch(e) {
            console.log(e)
            setServerErrors([e.toastMessage])
            setIsLoading(false)
        }
    }


    async function updateDoctor(doctor) {
        try {
            const formData = new FormData()
            formData.set('_method', 'put')
            formData.set('title_en', doctor.title_en)
            formData.set('title_ar', doctor.title_ar)
            formData.set('name_en', doctor.name_en)
            formData.set('name_ar', doctor.name_ar)
            formData.set('phone', doctor.phone)
            formData.set('price', doctor.price)
            formData.set('email', doctor.email)
            formData.set('about_ar', doctor.about_ar)
            formData.set('about_en', doctor.about_en)
            formData.set('specialization_id', doctor.specialization_id)
            formData.set('clinic_id', clinic.id)
            formData.set('experience', doctor.experience)
            formData.set('type', doctor.type)
            formData.set('license', doctor.license)
            doctor.languages.map((language, index) => formData.set(`languages[${index}]`, language))
            doctor.services.map((service, index) => formData.set(`services[${index}]`, service))
            selectedTags.map((tag, index) => formData.set(`tags[${index}]`, tag))
            console.log({selectedTags})
            if (doctor.image && doctor.image.length > 0) {
                formData.set('image', doctor.image[0])
            }

            setIsLoading(true)
            await ApiService.post(`/api/doctors/${doctor.id}`, formData)
            setIsLoading(false)
            onSuccess()
        } catch(e) {
            setServerErrors([e.toastMessage])
            setIsLoading(false)
        }
    }

    async function fetchSpecializations() {
        try {
            const response = await ApiService.get('/api/specializations')
            setSpecializations(response['data']['data'])
        } catch(e) {
            alert('Could not fetch the specializations.')
        }
    }

    function onImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader()

            reader.onload = function (e) {
                imageTag.current.src = e.target.result
            }

            reader.readAsDataURL(e.target.files[0])
        }
    }

    return(
        <div>
            <h4 className="pb-4 border-b border-gray-50 w-full text-center">
                { doctor.id ? t("Update Doctor") : t("Add New Doctor") }
            </h4>
            <div className="py-4">
                <div className="flex mx-auto">
                    <label htmlFor="image" className="cursor-pointer inline-block mx-auto text-center text-gray-500 text-sm">
                        <img src={doctor.id && doctor.image ? doctor.image : ImagePlacholder} ref={imageTag} className={`w-24 h-24 mx-auto object-cover object-center rounded-full border ${errors.image ? 'border-red-600' : 'border-gray-75' }`} alt="Logo"></img>
                        <span className={`mt-2 ${errors.image ? 'text-red-600' : '' }`}>{t("Choose Image")}</span>
                    </label>
                    <input onChange={onImageChange} type="file" id="image" name="image" accept="image/*" className="hidden" ref={register({required: false})}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("Title in Arabic")} <span className="text-red-600 text-sm ms-2">{ errors.title_ar?.message ?  (`${errors.title_ar.message}`) : ''  }</span></label>
                    <input defaultValue={doctor.title_ar} name="title_ar" type="text" className={`c-input mt-1 ${errors.title_ar ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                        value: 2,
                        message: t("Title must be more than 2 charachters.")
                    }})}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("Title in English")} <span className="text-red-600 text-sm ms-2">{ errors.title_en?.message ?  (`${errors.title_en.message}`) : ''  }</span></label>
                    <input defaultValue={doctor.title_en} name="title_en" type="text" className={`c-input mt-1 ${errors.title_en ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                            value: 2,
                            message: t("Title must be more than 2 charachters.")
                        }})}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("Arabic Name")} <span className="text-red-600 text-sm ms-2">{ errors.name_ar?.message ?  (`${errors.name_ar.message}`) : ''  }</span></label>
                    <input defaultValue={doctor.name_ar} name="name_ar" type="text" className={`c-input mt-1 ${errors.name_ar ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                        value: 2,
                        message: t("Name must be more than 2 charachters.")
                    }})}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("English Name")} <span className="text-red-600 text-sm ms-2">{ errors.name_en?.message ?  (`${errors.name_en.message}`) : ''  }</span></label>
                    <input defaultValue={doctor.name_en} name="name_en" type="text" className={`c-input mt-1 ${errors.name_en ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                            value: 2,
                            message: t("Name must be more than 2 charachters.")
                        }})}></input>
                </div>
                {/* <div className="mt-4">
                    <label className="c-label">{t("Phone Number")} <span className="text-red-600 text-sm ms-2">{ errors.phone?.message ?  (`${errors.phone.message}`) : ''  }</span></label>
                    <input defaultValue={doctor.phone} name="phone" placeholder="05xxxxxxxx" type="text" className={`c-input mt-1 ${errors.phone ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                        value: 10,
                        message: t("Phone number must be at least 10 digits.") // @todo: add saudio phone number validator
                    }})}></input>
                </div>  */}

                {/* Phone Number */}

                <InputForm
                    label={t("Phone Number")}
                    error={errors.phone?.message}
                    name="phone"
                    placeholder="05xxxxxxxx"
                    defaultValue={doctor.phone}
                    register={
                        register({
                            required: true,
                            minLength: {value: 10, message: t("Phone number must be at least 10 digits.") }
                        })
                    }
                />

                {/* License */}

                <InputForm
                    label={t("License")}
                    error={errors.license?.message}
                    name="license"
                    placeholder="xxxxx"
                    defaultValue={doctor.license}
                    register={
                        register({
                            required: true,
                            minLength: {value: 5, message: t("License must be at least 5 charachter.")}
                        })
                    }
                />

                {/* <div className="mt-4">
                    <label className="c-label">{t("License")} <span className="text-red-600 text-sm ms-2">{ errors.license?.message ?  (`${errors.license.message}`) : ''  }</span></label>
                    <input defaultValue={doctor.license} name="license" type="text" placeholder="xxxxx" className={`c-input mt-1 ${errors.license ? 'c-input--has-error' : ''}`} ref={register({required: true, minLength: {
                        value: 5,
                        message: t("License must be at least 5 charachter.") // @todo: add license validator
                    }})}></input>
                </div>  */}
                <div className="mt-4">

                    <label className="c-label">{t("Email")}
                        <span className="text-red-600 text-sm ms-2">
                            { errors.email?.message ?  (`${errors.email.message}`) : ''  }
                        </span>
                    </label>
                    <input defaultValue={doctor.email}
                            name="email"
                            type="email"
                            placeholder="example@email.com"
                            className={`c-input mt-1 ${errors.email ? 'c-input--has-error' : ''}`}

                            ref={register({
                                        required: false,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t("Invalid email address")
                                        }
                                    }
                                )}></input>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Specialization")} <span className="text-red-600 text-sm ms-2">{ errors.specialization?.message ?  (`${errors.specialization.message}`) : ''  }</span></label>
                    <select defaultValue={doctor?.specialization?.id} className="c-input" name="specialization_id" ref={register({required: true})}>
                        {specializations.map(specialization => <option selected={specialization.id == doctor.specialization?.id} key={specialization.id} value={specialization.id} >{ specialization.title_en } - { specialization.title_ar }</option> )}
                    </select>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Price")} <span className="text-red-600 text-sm ms-2">{ errors.price?.message ?  (`${errors.price.message}`) : ''  }</span></label>
                    <input
                        defaultValue={doctor.price}
                        name="price"
                        type="number"
                        placeholder="Price in SAR"
                        className={`c-input mt-1 ${errors.price ? 'c-input--has-error' : ''}`}
                        ref={register({required: true, min: {
                                value: 0,
                                message: t("At least 0")
                            }
                        })}></input>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Arabic About")} <span className="text-red-600 text-sm ms-2">{ errors.about_ar?.message ?  (`${errors.about_ar.message}`) : ''  }</span></label>
                    <textarea defaultValue={doctor.about_ar} className={`c-input mt-1 ${errors.about_ar ? 'c-input--has-error' : ''}`} name="about_ar" ref={register({required: true})}></textarea>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("English About")} <span className="text-red-600 text-sm ms-2">{ errors.about_en?.message ?  (`${errors.about_en.message}`) : ''  }</span></label>
                    <textarea defaultValue={doctor.about_en} className={`c-input mt-1 ${errors.about_en ? 'c-input--has-error' : ''}`} name="about_en" ref={register({required: true})}></textarea>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Experience")} <span className="text-red-600 text-sm ms-2">{ errors.experience?.message ?  (`${errors.experience.message}`) : ''  }</span></label>
                    <input name="experience"
                        type="number"
                        min="0"
                        max="50"
                        step="1"
                        className={`c-input mt-1 ${errors.experience ? 'c-input--has-error' : ''}`}
                        placeholder={t("Experience years")}
                        defaultValue={doctor.experience}
                        ref={
                        register({
                            required: true,
                            min: {
                                value: 0,
                                message: t("At least 0 years")
                            },
                            max: {
                                value: 50,
                                message: t("Should be less than 50")
                            }
                        })
                        }></input>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Doctor Type")} <span className="text-red-600 text-sm ms-2">{ errors.type?.message ?  (`${errors.type.message}`) : ''  }</span></label>
                    <select defaultValue={doctor.type} className="c-input" name="type" ref={register({required: true})} defaultValue={'virtual'}>
                        <option value='virtual'>{t("Virtual")}</option>
                        <option value='visit'>{t("Visit")}</option>
                        <option value='both'>{t("Both")}</option>
                    </select>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Service Type")} <span className="text-red-600 text-sm ms-2">{ errors.services?.message ?  (`${errors.services.message}`) : ''  }</span></label>
                    <div>
                        <input defaultChecked={doctor.id ? doctor?.services.includes('chat') : true} className="me-2" id="chat" name="services" type="checkbox" value="chat" ref={register}></input>
                        <label className="select-none" for="chat">{t("Chat")}</label>
                    </div>
                    <div>
                        <input defaultChecked={doctor.id ? doctor?.services.includes('video') : true} className="me-2" id="video" name="services" type="checkbox" value="video" ref={register}></input>
                        <label className="select-none" for="video">{t("Video")}</label>
                    </div>
                    <div>
                        <input defaultChecked={doctor.id ? doctor?.services.includes('voice') : true} className="me-2" id="voice" name="services" type="checkbox" value="voice" ref={register}></input>
                        <label className="select-none" for="voice">{t("Voice")}</label>
                    </div>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Languages")} <span className="text-red-600 text-sm ms-2">{ errors.about_en?.message ?  (`${errors.about_en.message}`) : ''  }</span></label>
                    <div>
                        <input defaultChecked={doctor.id ? doctor?.languages?.includes('ar') : true} className="me-2" id="ar" name="languages" type="checkbox" value="ar" ref={register}></input>
                        <label className="select-none" for="ar">{t("Arabic")}</label>
                    </div>
                    <div>
                        <input defaultChecked={doctor.id ? doctor.id.languages?.includes('en') : false} className="me-2" id="en" name="languages" type="checkbox" value="en" ref={register}></input>
                        <label className="select-none" for="en">{t("English")}</label>
                    </div>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Tags")} <span className="text-red-600 text-sm ms-2">{ errors.tags?.message ?  (`${errors.tags.message}`) : ''  }</span></label>
                    <Tags
                        onSelect={(tagId) => setSelectedTags([...selectedTags, tagId])}
                        onDeselect={(tagId) => setSelectedTags(selectedTags.filter(tag => tag !== tagId))}
                        initialSelectedTags={doctor.id ? doctor.tags : []}
                    />
                </div>

                <div className='mt-4'>
                    <ul className="text-red-700 font-bold list-disc ms-4">
                        { serverErrors.map(error => <li>{error}</li>) }
                    </ul>
                </div>
            </div>
            <div className="flex justify-center relative border-t border-gray-50 pt-4">
                <Button title={t("Save")} loading={isLoading} onClick={handleSubmit(onSubmit, onError)}></Button>
                <button className="px-2 py-1 absolute left-0" onClick={() => onCancel()}>{t("Cancel")}</button>
            </div>
        </div>
    )
}
