import React from 'react'
import Modal from 'react-modal'
import {useTranslation} from "react-i18next";
import Button from '@components/Button'
import formateDate from "../utils/format-date";
import formatTime from "../utils/format-time";
import axios from 'axios'
import {useToasts} from "react-toast-notifications";

export default function RescheduleAppointmentModal({isOpen, close, selectedAppointment = null}) {
    const {t} = useTranslation()
    const {addToast} = useToasts()

    if(!selectedAppointment){
        return <div></div>
    }
    let date, time = null
    return(
        <Modal
            isOpen={isOpen}
            onRequestClose={() => close()}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0, 0.6)'
                },
            }}
            overlayClassName="fixed top-0 bottom-0 left-0 right-0 overflow-y-scroll"
            className="bg-white w-full max-w-4xl mx-auto my-20 shadow rounded-md p-4 focus:outline-none"
        >
            <div className="mt-4">
                <label className="c-label">{t("Date")} </label>
                <input id="reschedule-date" name="date" type="date" className={`c-input mt-1`}></input>
            </div>

            <div className="mt-4">
                <label className="c-label">{t("Time")} </label>
                <input id="reschedule-time" name="time" type="time" className={`c-input mt-1`}></input>
            </div>
            <div className="flex justify-center relative border-t border-gray-50 pt-4">
                <Button title={t("Save")}
                        onClick={e => {
                            date = document.getElementById('reschedule-date').value
                            time = document.getElementById('reschedule-time').value
                            if(!date || !time){
                                return
                            }
                            axios.patch(`/api/appointments/${selectedAppointment.id}/reschedule`, {
                                start : date + ' ' + time
                            }).then(response => {
                                addToast(t('Rescheduled Successfully'), {appearance: 'success'})
                                selectedAppointment.start = response.data.data.start;
                                selectedAppointment.end = response.data.data.end;
                                close()
                            }).catch(e => {
                                addToast(t('In past or invalid'), {appearance: 'error'})
                            });
                        }}
                ></Button>
            </div>

        </Modal>
    )
}
