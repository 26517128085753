const initialState = {
    specializations: []
}

const specializationsReducer = (state = initialState, action) => {
    switch(action.type) {
    case 'SET_SPECIALIZATIONS':
        return {...state, specializations: action.specializations}
    default:
        return state
    }
}

export default specializationsReducer