import React from 'react'
import Modal from 'react-modal'
import EmployeeForm from './Forms/EmployeeForm'

export default function EmployeeFormModal({employee, isOpen, close, onSuccess, addToast}) {
    return(
        <Modal
            closeTimeoutMS={200}
            isOpen={isOpen}
            onRequestClose={() => close()}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0, 0.6)'
                },
            }}
            overlayClassName="fixed top-0 bottom-0 left-0 right-0 overflow-y-scroll"
            className="bg-white w-full max-w-4xl mx-auto my-20 shadow rounded-md p-4 focus:outline-none"
        >
            <EmployeeForm
                onSuccess={onSuccess}
                onCancel={close}
                employee={employee}
            ></EmployeeForm>
        </Modal>
    )
}
