import React from 'react'

export default function Row({title, content, titleRatio = '1/6' }) {
    return(
        <div className="flex flex-col md:flex-row border-b border-gray-50 py-4">
            <p className={`text-gray-500 w-full md:w-${titleRatio}`}>{title}</p>
            <div className="w-full font-bold text-primary">{content}</div>
        </div>
    )
}
