import React, {useState, useRef, useEffect} from 'react'
import Spinner from './Spinner'
import ApiService from '../services/api.service'
import {useTranslation} from 'react-i18next'
import {useHistory} from "react-router-dom"
import formateDate from "../utils/format-date"

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, callback = () => {}) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {document.removeEventListener("mousedown", handleClickOutside) }
    }, [ref])
}

export default function Notification() {
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [notifications, setNotifications] = useState([])
    const wrapperRef = useRef(null)
    const history = useHistory()
    const { t } = useTranslation()

    useOutsideAlerter(wrapperRef, () => setIsOpen(false))

    useEffect(() => {
        fetch()
    }, [])

    async function fetch() {
        try {
            setIsLoading(true)
            const response = await ApiService.get(`/api/providers/notifications`)
            setNotifications(response.data.data)
            setIsLoading(false)
        } catch(e) {
            setIsLoading(false)
        }
    }

    function NotificationButton() {
        return (
            <button onClick={() => setIsOpen(!isOpen)} className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:-none focus:text-white focus:bg-gray-700" aria-label="Notifications">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
            </button>
        )
    }

    function NotificationRow({notification}) {
        return (
            <li>
                <button onClick={() => goToReservation(notification.id)} to={`/reservations/${notification.id}`} className="block w-full p-3 border-b border-gray-50 text-sm cursor-pointer hover:bg-gray-50">
                    <div className="font-semibold text-start">
                        قام {notification.customer.name} بطلب {notification.service.name}
                    </div>
                    <div className="flex justify-between mt-2 text-xs text-gray-500">
                        <div>{notification.code}</div>
                        <div>{formateDate(notification.created_at)}</div>
                    </div>
                </button>
            </li>
        )
    }

    function goToReservation(id) {
        history.replace(`/reservations/${id}`)
        setIsOpen(false)
    }

    function EmptyState() {
        return <div className="h-64 w-full flex-center text-gray-500">{t("No Notifications")}</div>
    }

    function Loader() {
        return <div className="h-64 flex-center"><Spinner color="var(--color-primary)" size="40px"></Spinner></div>
    }

    function Notifications() {
        return (
            <ul>
                { notifications.map((notification) => <NotificationRow notification={notification}></NotificationRow>) }
            </ul>
        )
    }

    function Modal() {
        return (
            <div className="absolute top-0 mt-10 rtl:left-0 ltr:right-0 w-72 bg-white rounded border border-gray-50 shadow-lg z-10 overflow-y-scroll" style={{height: '400px'}} ref={wrapperRef}>
                { isLoading ? <Loader /> : notifications.length == 0 ? <EmptyState /> : <Notifications /> }
            </div>
        )
    }

    return (
        <div className="relative">
            <NotificationButton></NotificationButton>
            { isOpen && <Modal></Modal> }
        </div>
    )
}