const employeesReducer = (state = [], action) => {
    switch(action.type) {
    case 'SET_EMPLOYEES':
        return action.employees
    case 'TOGGLE_ACTIVE':
        return state.map(e => {
            if (e.id == action.id) {
                return {...e, is_active: !e.is_active}
            }
            return e
        })
        
        // const index = state.findIndex(employee => employee.id !== action.id)
        // const newEmployees = [...state] 
        // debugger
        // newEmployees[index].is_active = !newEmployees[index].is_active
        // return newEmployees
    default:
        return state
    }
}

export default employeesReducer