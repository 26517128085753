const ID_TOKEN_KEY = "token"

export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY)

export const setToken = (token) => window.localStorage.setItem(ID_TOKEN_KEY, token)

export const destroyToken = () => window.localStorage.removeItem(ID_TOKEN_KEY)

export const getUser = () => JSON.parse(window.localStorage.getItem('user'))

export const setUser = (user) => window.localStorage.setItem('user', JSON.stringify(user))

export const destroyUser = () => window.localStorage.removeItem('user')

export default {getToken, setToken, destroyToken, setUser, getUser, destroyUser}
