import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Loader from '@components/Loader'
import Button from '@components/Button'
import Status from '@components/Status'
import Pagination from '@components/Pagination'
import EmptyResult from '@components/EmptyResult'
import SearchInput from '@components/SearchInput'
import SearchResultSection from '@components/SearchResultSection'
import CardHeader from '@components/CardHeader'

import Layout from '@pages/Layout'
import store from '../store'
import ApiService from '../services/api.service'
import {useHistory, useLocation} from 'react-router-dom'

import fileDownload from 'js-file-download';
import formateDate from '../utils/format-date'
import queryString from 'query-string'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function ExportButton() {
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const {addToast} = useToasts()

    async function exportExcel() {
        try {
            setLoading(true)
            const response = await ApiService.get('api/providers/export-reservations-table', '', {responseType: 'blob'})
            fileDownload(response.data, 'reservations.xlsx')
        } catch(e) {
            alert('Couldn\'t export!')
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setLoading(false)
        }
    }

    return <Button title={<><span> {t("Export") }</span><span className="hidden md:inline-block px-1">{` ${t("Reservations")}`}</span></>} className="rounded-lg shadow-md" loading={loading} onClick={() => exportExcel()}></Button>
}


export default function Reservations() {
    const urlQuery = useQuery()
    const reservations = useSelector(state => state.reservations.reservations)
    const page = useSelector(state => state.reservations.page)
    const pagination = useSelector(state => state.reservations.pagination)
    const search = useSelector(state => state.reservations.search)
    const [hasMounted, setHasMounted] = useState(false)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const {t} = useTranslation()
    const {addToast} = useToasts()

    useEffect(() => {
        const initialSearch = urlQuery.get('search') || '' 
        const initialPage = urlQuery.get('page') || '' 

        if (!hasMounted) {
            initialSearch && store.dispatch({type: 'RESERVATIONS:SET_SEARCH', search: initialSearch})
            initialPage && store.dispatch({type: 'RESERVATIONS:SET_PAGE', page: initialPage})
            setHasMounted(true)
            fetchReservations(initialPage, initialSearch)
            return
        }
        
        fetchReservations(initialPage, initialSearch)
        setHasMounted(true)
    }, [page, search])

    function setPage(pageNumber) {
        store.dispatch({
            type: 'RESERVATIONS:SET_PAGE',
            page: pageNumber
        })

        let params = queryString.parse(urlQuery.toString())
        params = {...params, page: pageNumber}
        history.push({pathname: `/reservations`, search: queryString.stringify(params)})
    }

    async function fetchReservations(page, search) {
        try {
            store.dispatch({
                type: 'SET_RESERVATIONS',
                reservations: []
            })
            
            setLoading(true)

            const response = await ApiService.get(`api/providers/reservations-table?&page=${page}&search=${search}&x=x`)
            store.dispatch({
                type: 'RESERVATIONS:SET_PAGINATION',
                pagination: response['data']['pagination']
            })

            store.dispatch({
                type: 'SET_RESERVATIONS',
                reservations: response['data']['data']
            })

        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setLoading(false)
        }
    } 
    
    function performSearch(query) {
        store.dispatch({type: 'RESERVATIONS:SET_SEARCH', search: query})

        let params = queryString.parse(urlQuery.toString())
        params = {...params, search: query, page: 1}
        history.push({pathname: `/reservations`, search: queryString.stringify(params)})
    }

    function ReservationsTable({reservations}) {
        return (
            <table className="w-full border border-gray-50 text-sm md:text-base" style={{minWidth: '1200px'}}>
                <thead className="bg-gray-50">
                    <tr className="h-12 text-gray-600 border border-b-2 border-gray-75 text-start">
                        <th className="px-1 sm:px-4 w-12 border-l border-gray-75 text-center">#</th>
                        <th className="px-1 sm:px-4">{t("Code")}</th>
                        <th className="px-1 sm:px-4">{t("Service")}</th>
                        <th className="px-1 sm:px-4">{t("Customer")}</th>
                        <th className="px-1 sm:px-4">{t("Employee")}</th>
                        <th className="px-1 sm:px-4 text-center">{t("Amount")}</th>
                        <th className="px-1 sm:px-4 w-32">{t("Status")}</th>
                        <th className="px-1 sm:px-4">{t("Created At")}</th>
                        <th className="px-1 sm:px-4 text-center">{t("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {reservations.map((reservation, index) => {
                        return (
                            <tr className="h-12 border-b border-gray-50 hover:bg-gray-25 cursor-pointer" key={index.toString()}  onClick={() => history.push(`/reservations/${reservation.id}`)}>
                                <td className="px-2 sm:px-4 py-3 border-l border-gray-50 text-center">{ (index + 1 + (pagination.perPage * (pagination.currentPage - 1))).toString() }</td>
                                <td className="px-2 sm:px-4 py-3 w-20 md:w-40">{reservation.code}</td>
                                <td className="px-2 sm:px-4 py-3">{reservation.service.name}</td>
                                <td className="px-2 sm:px-4 py-3">{reservation.customer.name}</td>
                                <td className="px-2 sm:px-4 py-3">{reservation.employee.name}</td>
                                <td className="px-2 sm:px-4 py-3 ltr text-center">{reservation.amount} {reservation.currency?.name }</td>
                                <td className="px-2 sm:px-4 py-3"><Status reservation={reservation}></Status></td>
                                <td className="px-2 sm:px-4 py-3">{formateDate(reservation.created_at)}</td>
                                <td className="flex-center px-2 sm:px-4 py-3 border-r border-gray-50">
                                    <button className="px-2 py-1 text-xs border border-primary text-primary rounded-md" onClick={() => history.push(`/reservations/${reservation.id}`)}>{t("View")}</button>
                                </td>
                            </tr>    
                        )
                    })}
                </tbody>
            </table>
        )
    }
    
    function Content() {
        return (
            <div className="mx-auto">
                <CardHeader content={
                    <div className="flex justify-between items-center w-full">
                        <SearchInput initialSearch={search} onSearch={(q) => { performSearch(q) }} />
                        <ExportButton />
                    </div>
                }/>
                
                <SearchResultSection search={search} pagination={pagination} />
                
                <div className="overflow-x-scroll h-full">                    
                    <ReservationsTable reservations={reservations} />

                    { !loading && reservations.length > 0 && <Pagination total={pagination.totalPages} current={pagination.currentPage} onClick={(i) => setPage(i)} /> }

                    { loading && <Loader /> }
                    
                    { !loading && reservations.length === 0 && <EmptyResult content={ t("No Reservations Were Found.") } />}

                </div>
            </div>
        )
    }

    return (
        <Layout key="reservation-layout" content={<Content key="content" />} title={t("Reservations")}></Layout>
    )
} 