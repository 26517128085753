import React from 'react'

import '../assets/components/Spinner.css'

export default function Spinner(props) {
    
    const moonSize = parseFloat(props.size) / 5
    
    const spinnerStyle = {
        height: props.size,
        width: props.size,
        borderRadius: props.radius
    }

    const spinnerMoonStyle = {
        height: moonSize  + 'px',
        width: moonSize  + 'px',
        borderRadius: props.radius    
    }
    
    const animationStyle2 = {
        top:  parseFloat(props.size) / 2 - moonSize / 2 + 'px',
        backgroundColor: props.color
    }

    const animationStyle3 = {
        border: `${moonSize}px solid ${props.color}` 
    }


    return(
        <div className={`v-spinner ${props.className}`} style={{display: props.loading ? 'block' : 'none'}}>
            <div className="v-moon v-moon1" style={{...spinnerStyle}}>
                <div className="v-moon v-moon2" style={{...spinnerMoonStyle,...animationStyle2}}></div>
                <div className="v-moon v-moon3" style={{...spinnerStyle,...animationStyle3}}></div>
            </div>
        </div>
    )
}

Spinner.defaultProps = {
    loading: true,
    size: '20px',
    color: '#fff',
    radius: '100%'
}