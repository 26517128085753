const initialDoctor = {
    id: '',
    title: '', 
    name: '', 
    email: '', 
    phone: '', 
    price: '', 
    image: '', 
    about_ar: '', 
    about_en: '', 
    license: '', 
    type: '', 
    languages: ['ar'], 
    services: ['chat', 'video', 'voice'], 
    specialization_id: '',
    clinic_id: '', 
    experience: '',
    tags: [],
}

export default function makeDoctor(doctor = {...initialDoctor}) {    
    return Object.freeze({...doctor})
}