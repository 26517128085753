const initialState = {
    clinics: []
}

const clinicsReducer = (state = initialState, action) => {
    switch(action.type) {
    case 'SET_CLINICS':
        return {...state, clinics: action.clinics}
    default:
        return state
    }
}

export default clinicsReducer