import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'

import Loader from '@components/Loader'
import Button from '@components/Button'
import CardHeader from '@components/CardHeader'
import User from '@components/icons/outline/User'
import useUser from '@hooks/useUser'
import store from '@/store'
import Layout from '@pages/Layout'
import ApiService from '@services/api.service'
import EmployeeFormModal from '@components/Providers/EmployeeFormModal'

export default function Employees() {
    const employees = useSelector(state => state.employees)
    const history = useHistory()
    const provider = useUser()
    const [isLoading, setIsLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const {addToast} = useToasts()
    const { t } = useTranslation()
    function openModal() {
        setModalIsOpen(true)
    }

    function closeModal() {
        setModalIsOpen(false)
    }


    useEffect(() => {
        fetchEmployees()
    }, [])

    async function toggleActive(employeeId) {
        try {
            ApiService.post(`/api/employees/${employeeId}/active-inactive`)
            store.dispatch({
                type: 'TOGGLE_ACTIVE',
                id: employeeId
            })
        } catch(e) {

        }
    }

    async function fetchEmployees() {
        try {
            store.dispatch({
                type: 'SET_EMPLOYEES',
                employees: []
            })

            setIsLoading(true)

            const response = await ApiService.get(`/api/providers/${provider.id}/employees`)
            
            store.dispatch({
                type: 'SET_EMPLOYEES',
                employees: response['data']['data']
            })
            
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)

        }
    } 

    function onSuccess() {
        fetchEmployees()
        closeModal()
    }

    function EmployeeCard({employee}) {
        return (
            <div className="cursor-pointer block border-b border-gray-75 hover:bg-gray-25 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out" onClick={() => history.push(`/employees/${employee.id}`)}>
                <div className="flex items-center px-0 py-4 sm:px-4">
                    <div className="min-w-0 flex-1 flex items-center">
                        <div className="flex-shrink-0 md:block">
                            <img className="h-12 md:h-16 w-12 md:w-16 rounded-full border border-gray-100 object-cover object-center" src={provider.logo} alt="" />
                        </div>
                        <div className="flex flex-col md:flex-row flex-1 px-4">
                            <div className="w-full md:w-1/2">
                                <div className="text-sm leading-5 font-bold truncate">{ employee.name }</div>
                                <div className="flex items-center text-sm leading-5 text-gray-500 md:mt-2">
                                    <span className="truncate">{ employee.description }</span>  
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 flex">
                                <div className="flex items-center w-1/2 text-sm md:text-base">
                                    <User className="w-5 h-5 text-gray-400 me-1"></User>
                                    { employee.serve }
                                </div>
                                <div className="flex items-center w-1/2">
                                    <Button outline className="inline-flex items-center border-gray-200 text-xs md:text-base" title={
                                        <span className="flex items-center">
                                            <span className={`inline-block w-3 h-3 rounded-full me-2 ${employee.is_active ? 'bg-green-500' : 'bg-gray-500'}`}></span>
                                            { employee.is_active ? t("Active") : t("Deactivated") }
                                        </span>
                                    } onClick={(e) => {e.stopPropagation(); window.confirm( t("Are you sure?") )  && toggleActive(employee.id)}}>
                                        
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <svg className="h-5 w-5 text-gray-400 rtl:flip" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
    
    function Employees({employees}) {
        return (
            <>
                <EmployeeFormModal isOpen={modalIsOpen} close={() => closeModal()} onSuccess={() => onSuccess()}/>

                <CardHeader content={
                    <div className="ms-auto">
                        <Button title={ t("Add Employee") } onClick={openModal}></Button>
                    </div>
                }></CardHeader>

                {
                    employees.map(employee => {
                        return (<EmployeeCard key={`employee-${employee.id}`} employee={employee}></EmployeeCard>)
                    })
                }
            </>
        )
    }

    function Content() {
        return (isLoading ? <Loader /> : <Employees employees={employees} />)
    }

    return (
        <Layout content={<Content />} title={t("Employees")}>
        </Layout>
    )
} 