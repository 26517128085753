const initalState = {
    appointments: [],
    search: '',
    page: 1,
    pagination: {},
    isLoading: false
}

const appointmentsReducer = (state = initalState, action) => {
    switch(action.type) {
        case 'SET_APPOINTMENTS':
            return {...state, appointments: action.appointments}
        case 'UPDATE_APPOINTMENTS':
            return state.appointments.map((item, index) => {
                if (index !== action.index) {
                    return item
                }

                return {
                    ...item,
                    ...action.item
                }
            })
        case 'APPOINTMENTS:SET_SEARCH':
            return {...state, search: action.search}
        case 'APPOINTMENTS:SET_PAGINATION':
            return {...state, pagination: action.pagination}
        case 'APPOINTMENTS:SET_PAGE':
            return {...state, page: action.page}
        default:
            return state
    }
}

export default appointmentsReducer
