
export default function makeReservation(reservation = {}) {
    return Object.freeze({
        id: reservation.id,
        start: reservation.start,
        end: reservation.end,
        status: reservation.status,
        statusNo: reservation.statusNo,
        address: reservation.address,
        lat: reservation.lat,
        lng: reservation.lng,
        amount: reservation.amount,
        quantity: reservation.quantity,
        currency: reservation.currency,
        gender: reservation.gender,
        comment: reservation.comment,
        code: reservation.code,
        created_at: reservation.created_at,
        service: reservation.service,
        category: reservation.category,
        review: reservation.review,
        review_text: reservation.review_text,
        results: reservation.results,
        voiceNotes: reservation.voiceNotes,
        documents: reservation.documents,
        customer: reservation.customer,
        employee: reservation.employee,
    })
}