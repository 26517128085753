import React, {useEffect, useState, useRef} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {useToasts} from 'react-toast-notifications'
import {useTranslation} from 'react-i18next'

import BackButton from '@components/BackButton'
import Button from '@components/Button'
import Loader from '@components/Loader'
import Row from '@components/Row'
import ImagePlacholder from '@assets/imgs/image-placholder.png'

import Layout from '@pages/Layout'
import useLocale from '@hooks/useLocale'
import dayOfWeek from '@utils/day-of-week'
import ApiService from '@services/api.service'
import {DoctorLanguages} from '@utils/doctor-languages'

function WorkingTimeTable({initialWorkingHours = []}) {
    const {id} = useParams()
    const {t} = useTranslation()
    const {addToast} = useToasts()

    const [workingDay, setWorkingDay] = useState(0)
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [workingHours, setWorkingHours] = useState(initialWorkingHours)

    function convertTimeToTimestamp(time) {
        const date = new Date(day)
        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDay()
        const hour = time.split(':')[0]
        const minutes = time.split(':')[1]

        return toTimestamp(year, month, day, hour, minutes)
    }

    function toTimestamp(year, month, day, hour, minute, second = 0) {
        var datum = new Date(Date.UTC(year, month - 1, day, hour, minute, second = 0))
        return datum.getTime() / 1000
    }

    function Table() {
        return (
            <table className="table max-w-2xl border border-gray-100 mb-0">
                <thead>
                <tr>
                    <th></th>
                    <th scope="col" className="text-center">{t("From")}</th>
                    <th scope="col" className="text-center">{t("To")}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>

                {
                    workingHours.sort((a, b) => a.day - b.day).map((slot, index) => {
                        return (
                            <tr key={`slot-${slot.id}`}>
                                <td className="font-medium text-center">{dayOfWeek(slot.day)}</td>
                                <td className="font-medium text-center">{(slot.start)}</td>
                                <td className="font-medium text-center">{(slot.end)}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        )
    }

    async function addSlot() {
        const regex = /^0[0-9]|1[0-9]|2[0-3]:[0-5][0-9]$/
        if (!regex.test(start) || !regex.test(end)) {
            addToast(t("Invalid time format"), {appearance: 'error'})
            return
        }
        let _start = (new Date()).setHours(...start.split(':'))
        let _end = (new Date()).setHours(...end.split(':'))
        if (_start > _end) {
            addToast(t("Start can't be after end"), {appearance: 'error'})
            return
        }
        if (_start === _end) {
            addToast(t("Start can't be equal to end"), {appearance: 'error'})
            return
        }
        try {
            const response = await ApiService.post(`/api/doctors/working-hours`, {
                "day": workingDay,
                "start": start,
                "end": end,
                "doctor_id": id,
            })

            setWorkingHours([...workingHours, response.data])
        } catch (e) {
            console.log(e)
        }
    }

    async function removeSlot(id) {
        try {
            if (!window.confirm(t("Are you sure?"))) return

            ApiService.delete(`/api/doctors/working-hours/${id}`)

            setWorkingHours(workingHours.filter(hour => hour.id !== id))
        } catch (e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    return <Table></Table>
}

export default function SingleDoctor() {
    const [doctor, setDoctor] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const {id} = useParams()
    const {addToast} = useToasts()
    const {t} = useTranslation()
    const locale = useLocale()
    useEffect(() => {
        fetchDoctor()
    }, [id])

    async function fetchDoctor() {
        try {
            setIsLoading(true)
            const response = await ApiService.get(`api/doctors/${id}`)
            setDoctor(response['data']['data'])
        } catch (e) {
            if (e.response.status == 401) {
                history.push('/')
                return
            }

            if (e.response.status == 404) {
                addToast('Doctor not found.', {appearance: 'error'})
                history.push('/clinics')
                return
            }

            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)
        }
    }


    function Doctor() {
        return (
            <div>
                <div className="pb-6 border-b border-gray-50">
                    <BackButton className="mr-auto"></BackButton>
                </div>

                <h2 className="text-lg md:text-2xl text-center mt-6">{doctor.service?.name}</h2>
                <h5 className="text-base text-center text-gray-400 mt-1">{doctor.code}</h5>

                <div className={isLoading ? 'hidden' : undefined}>
                    <div className="text-2xl flex-center mt-4">
                        <img className="w-24 h-24 mx-auto object-cover object-center rounded-full border border-gray-75"
                             src={doctor.image ? doctor.image : ImagePlacholder} alt="Logo"></img>
                    </div>

                    <div className="flex justify-between border-b border-gray-100 py-2">
                        <p className="font-bold text-primary text-xl py-2">{t("General Information")}</p>
                    </div>

                    <Row title={t("Title")} content={doctor.title}></Row>
                    <Row title={t("Name")} content={doctor.name}></Row>
                    <Row title={t("Phone")} content={doctor.phone}></Row>
                    <Row title={t("Email")} content={doctor.email}></Row>
                    <Row title={t("Specialization")} content={doctor.specialization.title}></Row>
                    <Row title={t("Clinic")} content={doctor.clinic.name}></Row>
                    <Row title={t("Arabic About")}
                         content={<span className="max-w-2xl">{doctor.about_ar} </span>}></Row>
                    <Row title={t("English About")}
                         content={<span className="max-w-2xl">{doctor.about_en} </span>}></Row>
                    <Row title={t("Experience")} content={`${doctor.experience} ${t("Years")}`}></Row>
                    <Row title={t("Type")} content={doctor.type}></Row>
                    <Row title={t("License")} content={doctor.license}></Row>
                    <Row title={t("Languages")} content={doctor.languages.map(lang => <span
                        className="px-2"> {t(DoctorLanguages[lang])}</span>)}></Row>
                    <Row title={t("Tags")} content={doctor.tags.map(tag => <span
                        className="border border-gray-100 text-sm rounded px-1 mx-2"> {tag.name}</span>)}></Row>

                    <div className="flex justify-between border-b border-gray-100 py-2">
                        <p className="font-bold text-primary text-xl py-2">{t("Working Hours")}</p>
                    </div>

                    <div>
                        <WorkingTimeTable initialWorkingHours={doctor.workingHours}></WorkingTimeTable>
                    </div>
                </div>
            </div>
        )
    }

    function Content() {
        return isLoading ? <Loader></Loader> : <Doctor></Doctor>
    }

    return (
        <Layout content={<Content/>} title={!isLoading && <> {doctor.name} </>}></Layout>
    )
}
