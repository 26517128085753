
export default function makeUser(user = {}) {
    user = user || {}
    
    if (!user.type) {
        console.error('User type is missing!')
    }

    return Object.freeze({
        ...user,

        isProvider: () => user.type == 'providers',
        isCustomer: () => user.type == 'customer',
        isDoctor: () => user.type == 'doctors',
        isClinic: () => user.type == 'clinics',
        isAdmin: () => user.type == 'users',
    })
}