import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'
import {useHistory, useParams} from 'react-router-dom'
import Loader from '@components/Loader'
import CardHeader from '@components/CardHeader'
import Layout from '@pages/Layout'
import ApiService from '@services/api.service'
import formateDate from '@utils/format-date'
import AppointmentModal from "./AppointmentModal";
import axios from "axios";
import RescheduleAppointmentModal from "../Doctors/RescheduleAppointmentModal"

export default function Appointments() {
    const history = useHistory()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [rescheduleModalIsOpen, setRescheduleModalIsOPen] = useState(false)
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const {t} = useTranslation()
    const {addToast} = useToasts()
    const [loading, setLoading] = useState(true)
    const [appointments, setAppointments] = useState([])
    const {id} = useParams()

    useEffect(() => {
        fetchAppointments()
    }, [])

    async function fetchAppointments() {
        try {
            setLoading(true)
            setAppointments([])
            const response = await ApiService.get(`api/doctors/${id}/appointments`)
            setAppointments(response['data']['data'])
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setLoading(false)
        }
    }

    function AppointmentsTable({appointments}) {
        return (
            <table className="c-table" style={{minWidth: '1200px'}}>
                <thead>
                    <tr>
                        <th className="px-1 sm:px-4 w-12 border-l border-gray-75 text-center">#</th>
                        <th className="px-1 w-20 sm:px-4">{t("Code")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("Amount")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("Customer")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("Customer Phone")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Specialization")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Status")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Date")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Created at")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.map((appointment, index) => {
                        return (
                            <tr className="hover:bg-gray-25 cursor-pointer" key={index.toString()}
                                onClick={e => {
                                    e.stopPropagation()
                                    setSelectedAppointment(appointment)
                                    setModalIsOpen(true)
                                }}
                            >
                                <td className="px-2 sm:px-4 py-3 border-l border-gray-50 text-center">{ (index + 1).toString() }</td>
                                <td className="px-2 sm:px-4 py-3 w-20">{appointment.code}</td>
                                <td className="px-2 sm:px-4 py-3 w-20 md:w-40">{appointment.amount}</td>
                                <td className="px-2 sm:px-4 py-3">{appointment.customer?.name}</td>
                                <td className="px-2 sm:px-4 py-3">{appointment.customer?.phone}</td>
                                <td className="px-2 sm:px-4 py-3">{appointment.doctor?.specialization?.title}</td>
                                <td className="px-2 sm:px-4 py-3">{appointment.status}</td>
                                <td className="px-2 sm:px-4 py-3">{formateDate(appointment.start)}</td>
                                <td className="px-2 sm:px-4 py-3">{appointment.created_at}</td>
                                <td className="px-2 sm:px-4 py-3 cursor-default" onClick={e =>
                                    e.stopPropagation()
                                }>
                                    <button className="px-2 py-1 my-1 mx-1 text-xs border border-primary text-primary rounded-md"
                                            onClick={ e => {
                                                e.stopPropagation()
                                                setSelectedAppointment(appointment)
                                                setRescheduleModalIsOPen(true)
                                            }}
                                    >
                                        {t("Edit time")}
                                    </button>

                                    <ChangeStatusButtons appointment={appointment}/>
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }
    function ChangeStatusButtons({appointment}) {
        if(appointment.status_id != 2){
            return <span></span>;
        }
        return (
            <>
                <button className="px-2 py-1 my-1 mx-1 text-xs border border-primary text-primary rounded-md"
                        onClick={ e => {
                            e.stopPropagation()
                            if(!window.confirm('Are you sure')){
                                return;
                            }
                            axios.patch(`/api/appointments/${appointment.id}/status/cancelled`)
                                .then(response => {
                                    appointment.status = response.data.data.status
                                    appointment.status_id = response.data.data.status_id
                                    addToast(t('action_done'), {appearance: 'success'})
                                }).catch(() => addToast(t('something_went_wrong'), {appearance: 'error'}))
                        }}
                >
                    {t("Status_Cancelled")}
                </button>

                <button className="px-2 py-1 my-1 mx-1 text-xs border border-primary text-primary rounded-md"
                        onClick={ e => {
                            e.stopPropagation()
                            if(!window.confirm('Are you sure')){
                                return;
                            }
                            axios.patch(`/api/appointments/${appointment.id}/status/done`)
                                .then(response => {
                                    appointment = response.data.data
                                    addToast(t('action_done'), {appearance: 'success'})
                                }).catch(() => addToast(t('something_went_wrong'), {appearance: 'error'}))
                        }}
                >
                    {t("Status_Done")}
                </button>
            </>
        )
    }

    function Content() {
        return (
            <>
                <AppointmentModal isOpen={modalIsOpen} close={() => setModalIsOpen(false)}  appointment={selectedAppointment}/>
                <RescheduleAppointmentModal isOpen={rescheduleModalIsOpen} close={() => setRescheduleModalIsOPen(false)} selectedAppointment={selectedAppointment}/>
                <CardHeader />

                <div className="overflow-x-scroll h-full">
                    <AppointmentsTable appointments={appointments} />
                    {
                        loading && <Loader />
                    }
                    {
                        !loading && appointments.length === 0 && (
                            <p className="h-48 flex-center text-lg text-gray-500">{t("No Appointments Were Found.")}</p>
                        )
                    }
                </div>
            </>
        )
    }

    return (
        <Layout key="reservation-layout" content={<Content key="content" />} title={t("Appointments")} />
    )
}
