import useLocale from '../hooks/useLocale'

const options = {
    hour: 'numeric', minute: 'numeric',
    hour12: true,
    timeZone: 'Asia/Riyadh' 
}

const locale = useLocale() == 'en' ? 'en-GB' : 'ar-EG'

export default unixTimestamp => new Intl.DateTimeFormat(locale, options).format(new Date(unixTimestamp * 1000))