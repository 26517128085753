import React, {useState} from 'react'
import formatDate from '../../utils/format-date'
import getHour from '../../utils/get-hour'
import Button from '../Button'
import ApiService from '../../services/api.service'
import AuthService from '../../services/auth.service'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'

export default function BlockTimeTable({intialProvider}) {
    const [provider, setProvider] = useState({...intialProvider})
    const [blockDay, setBlockDay] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const { t } = useTranslation()
    const {addToast} = useToasts()

    function convertTimeToTimestamp(time) {
        const date = new Date(blockDay)
        const year = date.getFullYear()
        const month = date.getMonth()
        const day = date.getDay()
        const hour = time.split(':')[0]
        const minutes = time.split(':')[1]

        return toTimestamp(year, month, day, hour, minutes)
    }

    function toTimestamp(year,month,day,hour,minute,second = 0){
        var datum = new Date(Date.UTC(year,month-1,day,hour,minute,second = 0))
        return datum.getTime()/1000
    }

    function Table() {
        return (
            <>
                <table className="table max-w-2xl border border-gray-100 mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th scope="col" className="text-center">{t("From")}</th>
                            <th scope="col" className="text-center">{t("To")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        provider?.blockTime.map(d => 
                            d.hours.map((hour, index) => {
                                return (
                                    <tr key={`hour-${hour.id}`}>
                                        { index == 0 && <th className="border-l border-gray-100" scope="row" rowSpan={index == 0 && d.hours.length > 0 ? d.hours.length : 1}>{ formatDate(d.day) }</th> }
                                        <td className="font-medium text-center">{ getHour(hour.start) }</td>
                                        <td className="font-medium text-center">{ getHour(hour.end) }</td>
                                        <td className="font-medium text-center border-r border-gray-100">
                                            <button className="text-red-600 p-1" onClick={() => removeBlockTime(hour.id)}>{t("Delete")}</button>
                                        </td>
                                    </tr>
                                )
                            })
                        )
                        }

                        <tr className="border-t-2 border-gray-100">
                            <td>
                                <input className="c-input" id="day" value={blockDay} type="date" onChange={(e) => {setBlockDay(e.target.value)}}></input>
                            </td>
                            <td>
                                <input className="c-input" id="from" type="time" value={start} onChange={(e) => setStart(e.target.value)}></input>
                            </td>
                            <td>
                                <input className="c-input" id="to" type="time" value={end} onChange={(e) => setEnd(e.target.value)}></input>
                            </td>
                            <td>
                                <Button title={t("Add")} disabled={blockDay == null || start == null || end == null} onClick={addBlockTime}></Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }
    
    async function addBlockTime() {
        try {
            await ApiService.post(`/api/providers/blocktime`, {
                "day": new Date(blockDay).getTime() / 1000,
                "start": convertTimeToTimestamp(start),
                "end": convertTimeToTimestamp(end),
            })

            const response = await AuthService.fetch()
            setProvider(response.data.data)

        } catch(e) {
            console.log(e)
        }
    }

    async function removeBlockTime(id) {
        try {
            if (! window.confirm( t("Are you sure?") )) return

            await ApiService.post(`/api/providers/blocktime/delete`, {"id": id})
            
            setProvider(
                {...provider, blockTime: provider.blockTime.map(day => {
                    return {
                        ...day,
                        hours: day.hours.filter(h => h.id !== id)
                    }
                })}
            )
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    return <Table></Table>
}