import React from 'react'
import {useRef, useState} from 'react'
import {useForm} from "react-hook-form"

import Button from '@components/Button'
import ImagePlacholder from '../../assets/imgs/image-placholder.png'
import ApiService from '@services/api.service'
import useUser from '../../hooks/useUser'
import dayOfWeek from '../../utils/day-of-week'
import getHour from '../../utils/get-hour'
import {useToasts} from 'react-toast-notifications'
import {useTranslation} from 'react-i18next'

export default function ClinicForm({clinic = {}, onSuccess, onCancel}) {
    const {register, handleSubmit, errors, setValue, getValues} = useForm()
    const onSubmit = (data) => {
        // if(!clinic.latitude || !clinic.longitude){
        //     document.getElementById('location_text').classList.add('c-input--has-error')
        //     return;
        // }
        clinic.id ? updateClinic(data) : addClinic(data)
    }
    const onError = (errors, e) => {
        // if(!clinic.latitude || !clinic.longitude){
        //     document.getElementById('location_text').classList.add('c-input--has-error')
        // }
        console.log(errors, e)
    }
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [serverErrors, setServerErrors] = useState([])
    const provider = useUser()
    const imageTag = useRef(null)

    initMap();

    function initMap() {
        let defaultCenter = clinic.latitude && clinic.longitude ? {lat: clinic.latitude * 1, lng: clinic.longitude * 1} : {lat: 24.7136, lng: 46.6753};
        if (!(window.google && document.getElementById("map"))) {
            setTimeout(initMap, 500);
            return;
        }
        const map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 6,
            center: defaultCenter,
            gestureHandling: 'cooperative'
        });
        const geocoder = new window.google.maps.Geocoder();
        let marker = new window.google.maps.Marker({
            position: defaultCenter,
            draggable: true,
            map,
        });
        marker.addListener('dragend', event => {
            detectAddress(geocoder, event.latLng);
            clinic.latitude = event.latLng.lat();
            clinic.longitude = event.latLng.lng();
        });
        if(clinic.latitude && clinic.longitude){
            detectAddress(geocoder, defaultCenter)
        }
    }

    function detectAddress(geocoder, latLng) {
        let locationAddress = document.getElementById('location_text');
        // locationAddress.classList.remove('c-input--has-error');
        geocoder.geocode({location: latLng}, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    locationAddress.value = results[0].formatted_address;
                } else {
                    console.log("No results found");
                }
            } else {
                console.log("Geocoder failed due to: " + status);
            }
        });
    }

    async function addClinic({logo, name_ar, name_en, email, phone}) {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.set('name_ar', name_ar)
            formData.set('name_en', name_en)
            formData.set('phone', phone)
            formData.set('email', email)
            if(clinic.latitude){
                formData.set('latitude', clinic.latitude);
            }
            if(clinic.longitude){
                formData.set('longitude', clinic.longitude);
            }
            logo && logo.length > 0 && formData.set('logo', logo[0])

            await ApiService.post(`/api/clinics`, formData)
            setIsLoading(false)
            onSuccess()
        } catch (e) {
            setServerErrors([e.toastMessage])
            setIsLoading(false)
        }
    }

    async function updateClinic({logo, name_ar, name_en, email, phone}) {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.set('_method', 'put')
            formData.set('name_ar', name_ar)
            formData.set('name_en', name_en)
            formData.set('phone', phone)
            formData.set('email', email)
            if(clinic.latitude){
                formData.set('latitude', clinic.latitude);
            }
            if(clinic.longitude){
                formData.set('longitude', clinic.longitude);
            }
            logo && logo.length > 0 && formData.set('logo', logo[0])
            await ApiService.post(`/api/clinics/${clinic.id}`, formData)
            setIsLoading(false)
            onSuccess()
        } catch (e) {
            setServerErrors([e.toastMessage])
            setIsLoading(false)
        }
    }

    function onImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader()

            reader.onload = function (e) {
                imageTag.current.src = e.target.result
            }

            reader.readAsDataURL(e.target.files[0])
        }
    }

    return (
        <div>
            <h4 className="pb-4 border-b border-gray-50 w-full text-center">
                {t("Add New Clinic")}
            </h4>
            <div className="py-4">
                <div className="flex mx-auto">
                    <label htmlFor="logo"
                           className="cursor-pointer inline-block mx-auto text-center text-gray-500 text-sm">
                        <img src={clinic.id ? clinic.logo : ImagePlacholder} ref={imageTag}
                             className={`w-24 h-24 mx-auto object-cover object-center rounded-full border ${errors.logo ? 'border-red-600' : 'border-gray-75'}`}
                             alt="Logo"></img>
                        <span className={`mt-2 ${errors.logo ? 'text-red-600' : ''}`}>{t("Choose Image")}</span>
                    </label>
                    <input onChange={onImageChange} type="file" id="logo" name="logo" accept="image/*"
                           className="hidden" ref={register({required: false})}></input>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Arabic Name")} <span
                        className="text-red-600 text-sm ms-2">{errors.name_ar?.message ? (`${errors.name_ar.message}`) : ''}</span></label>
                    <input defaultValue={clinic.name_ar} placeholder="الاسم بالعربية" name="name_ar" type="text"
                           className={`c-input mt-1 ${errors.name_ar ? 'c-input--has-error' : ''}`} ref={register({
                        required: true, minLength: {
                            value: 3,
                            message: t("Name must be more than 2 charachters.")
                        }
                    })}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("English Name")} <span
                        className="text-red-600 text-sm ms-2">{errors.name_en?.message ? (`${errors.name_en.message}`) : ''}</span></label>
                    <input defaultValue={clinic.name_en} placeholder="English Name" name="name_en" type="text"
                           className={`c-input mt-1 ${errors.name_en ? 'c-input--has-error' : ''}`} ref={register({
                        required: true, minLength: {
                            value: 3,
                            message: t("Name must be more than 2 charachters.")
                        }
                    })}></input>
                </div>

                <div className="mt-4">
                    <label className="c-label">{t("Phone Number")} <span
                        className="text-red-600 text-sm ms-2">{errors.phone?.message ? (`${errors.phone.message}`) : ''}</span></label>
                    <input defaultValue={clinic.phone} placeholder="05xxxxxxxx" name="phone" type="number"
                           className={`c-input mt-1 ${errors.phone ? 'c-input--has-error' : ''}`} ref={register({
                        required: true, minLength: {
                            value: 10,
                            message: t("Phone number must be at least 10 digits.") // @todo: add saudio phone number validator
                        }
                    })}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("Email")}
                        <span className="text-red-600 text-sm ms-2">
                            {errors.email?.message ? (`${errors.email.message}`) : ''}
                        </span>
                    </label>
                    <input defaultValue={clinic.email}
                           name="email"
                           type="email"
                           placeholder="example@email.com"
                           className={`c-input mt-1 ${errors.email ? 'c-input--has-error' : ''}`}
                           ref={register({
                                   required: false,
                                   pattern: {
                                       value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                       message: t("Invalid email address")
                                   }
                               }
                           )}></input>
                </div>
                <div className="mt-4">
                    <label className="c-label">{t("Location")} <span
                        className="text-red-600 text-sm ms-2">{errors.name_en?.message ? (`${errors.name_en.message}`) : ''}</span></label>
                    <input readOnly disabled id="location_text" className="c-input mt-1"
                           value={t('Drag marker to set location')}></input>

                </div>
                <div className='mt-4 h-72' id="map">
                </div>

                <div className='mt-4'>
                    <ul className="text-red-700 font-bold list-disc ms-4">
                        {serverErrors.map(error => <li>{error}</li>)}
                    </ul>
                </div>
            </div>
            <div className="flex justify-center relative border-t border-gray-50 pt-4">
                <Button title={t("Save")} loading={isLoading} onClick={handleSubmit(onSubmit, onError)}></Button>
                <button className="px-2 py-1 absolute left-0" onClick={() => onCancel()}>{t("Cancel")}</button>
            </div>
        </div>
    )
}
