import React from 'react'
import Modal from 'react-modal'
import SpecializationForm from './Forms/SpecializationForm'

export default function SpecializationFormModal({isOpen, close, onSuccess, specialization = {}}) {
    return(        
        <Modal
            isOpen={isOpen}
            onRequestClose={() => close()}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0, 0.6)'
                },
            }}
            overlayClassName="fixed top-0 bottom-0 left-0 right-0 overflow-y-scroll"
            className="bg-white w-full max-w-4xl mx-auto my-20 shadow rounded-md p-4 focus:outline-none"
        >
            <SpecializationForm
                onSuccess={onSuccess}
                onCancel={close}
                specialization={specialization}
            ></SpecializationForm>
        </Modal>
    )
}
