import React from 'react'
import Nav from '@components/Nav'
export default function Layout({content, title}) {
    return (
        <div>
            <div className="bg-primary-darker pb-32">
                <Nav></Nav>  
                
                <header className="py-5 md:py-10">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="text-lg md:text-3xl leading-9 font-bold text-white">
                            { title }
                        </h1>
                    </div>
                </header>
            </div>
            <main className="-mt-32">
                <div className="max-w-7xl mx-auto pb-12 px-2 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-lg shadow px-3 py-6 sm:px-6 overflow-y-scroll relative" style={{minHeight: 'calc(100vh - 200px)'} }>
                        { content }        
                    </div>
                </div>
            </main>
        </div>
    )
}