import React from 'react'
import Spinner from './Spinner'

export default function Button({title, loading, className, danger = false, green = false, outline = false, onClick, disabled = false}) {
    let buttonClass = 'c-button'

    if (danger) {
        buttonClass = 'c-button--danger'
    }

    if (green) {
        buttonClass = 'c-button--green'
    }

    if (outline) {
        buttonClass = 'c-button--outline'
    }

    return (
        <button disabled={disabled} className={`${buttonClass} relative flex justify-center px-3 py-1 items-center transition-all duration-200 ${disabled ? 'cursor-not-allowed' : '' } ${className}`} onClick={onClick}>
            { loading &&  <Spinner loading={loading}z className="absolute"></Spinner> }
            <span className={loading ? 'text-transparent' : ''}>{ title }</span>
        </button>
    )
}