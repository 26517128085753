import React from 'react'


const PENDING = 1
const CONFIRMED = 2
const CANCEL = 3
const DONE = 4

export default function Status({reservation}) {
    let background
    let textColor
    switch (reservation.statusNo) {
    case PENDING:
        background = 'bg-orange-100 border border-orange-200'
        textColor = 'text-orange-900'
        break
    case CONFIRMED: 
        background = 'bg-blue-100 border border-blue-200'
        textColor = 'text-blue-900'
        break
    case CANCEL:
        background = 'bg-red-100 border border-red-200'
        textColor = 'text-red-900'
        break
    case DONE:
        background = 'bg-green-100 border border-green-200'
        textColor = 'text-green-900'
        break
    default:
        background = 'bg-green-100 border border-green-200'
        textColor = 'text-green-900'
    }

    return (
        <span className={`flex items-center py-1 px-2 rounded-md text-center text-sm ${background}`}>
            <span className={`block w-full ${textColor}`}>{ reservation.status }</span>
        </span>
    )
}