import React from 'react'
import {useRef, useState} from 'react'
import {useForm} from "react-hook-form"

import Button from '@components/Button'
// import ImagePlacholder from '../assets/imgs/image-placholder.png'
import ApiService from '@services/api.service'
import useUser from '@hooks/useUser'

import dayOfWeek from '@utils/day-of-week'
import getHour from '@utils/get-hour'
import {useTranslation} from 'react-i18next'

export default function EmployeeForm({employee = {}, onSuccess, onCancel, addToast = () => {} }) {
    const {register, handleSubmit, errors, setValue, getValues} = useForm()
    const onSubmit = (data) => addEmplyee(data)
    const onError = (errors, e) => console.log(errors, e)
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const provider = useUser()
    
    function toggleAll(e) {
        const ids = e.target.checked ? provider.workingDays?.flatMap(day => day.workinHours.flatMap((hour, index) => hour.id.toString())) : []
        setValue('workinHours', ids)
    }

    function WorkingHoursTable({workingDays}) {
        return(
            <table className="table border border-gray-100 mb-0 mt-4">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" onChange={(e) => toggleAll(e)} />
                        </th>
                        <th></th>
                        <th scope="col" className="text-center">{t("From")}</th>
                        <th scope="col" className="text-center">{t("To")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        workingDays?.map(day => 
                            day.workinHours.map((hour, index) => {
                                return (
                                    <tr key={`hour-${hour.id}`}>
                                        <th className="border-l border-gray-100">
                                            <label className="w-full block cursor-pointer" htmlFor={`hour-${hour.id}`}>
                                                <input ref={register()} id={`hour-${hour.id}`} name={`workinHours`} type="checkbox" value={hour.id}></input>
                                            </label>
                                        </th>
                                        <th className="border-l border-gray-100" scope="row">{ dayOfWeek(day.day) }</th>
                                        <td className="font-medium text-center">{ getHour(hour.start) }</td>
                                        <td className="font-medium text-center">{ getHour(hour.end) }</td>
                                    </tr>
                                )
                            })
                        )
                    }
                </tbody>
            </table>
        )
    }

    async function addEmplyee({logo, name_ar, name_en, duration, serve, workinHours}) {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.set('name_ar', name_ar)
            formData.set('name_en', name_en)
            formData.set('duration', duration)
            formData.set('serve', serve)

            if (workinHours && workinHours.length) {
                workinHours.forEach(attach => formData.append("workingHours[]", attach))
            } else {
                setIsLoading(false)
                alert(t("Please select Working Days slots"))
                return
            }

            await ApiService.post(`/api/providers/employees`, formData)
            setIsLoading(false)
            onSuccess()
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)
        }
    }
    
    // function onImageChange(e) {
    //     if (e.target.files && e.target.files[0]) {
    //         let reader = new FileReader()

    //         reader.onload = function (e) {
    //             imageTag.current.src = e.target.result 
    //         }

    //         reader.readAsDataURL(e.target.files[0])
    //     }
    // }

    return(
        <div>
            <h4 className="pb-4 border-b border-gray-50 w-full text-center">
                {t("Add New Employee")}
            </h4>
            
            <div className="py-4">
                {/* <div className="flex mx-auto">
                    <label htmlFor="logo" className="cursor-pointer inline-block mx-auto text-center text-gray-500 text-sm">
                        <img src={employee.id ? employee.logo : ImagePlacholder} ref={imageTag} className={`w-24 h-24 mx-auto object-cover object-center rounded-full border ${errors.logo ? 'border-red-600' : 'border-gray-75' }`} alt="Logo"></img>
                        <span className={`mt-2 ${errors.logo ? 'text-red-600' : '' }`}>اختر صورة</span>
                    </label>
                    <input onChange={onImageChange} type="file" id="logo" name="logo" accept="image/*" className="hidden" ref={register({required: true})}></input>
                </div> */}
               
                <div className="mt-4">
                    <label className="c-label">{t("Arabic Name")} <span className="text-red-600 text-sm ms-2">{ errors.name_ar?.message ?  (`${errors.name_ar.message}`) : ''  }</span></label>
                    <input defaultValue= {''} name="name_ar" type="text" className={`c-input mt-1 ${errors.name_ar ? 'c-input--has-error' : ''}`} placeholder="" ref={register({required: true, minLength: {
                        value: 3,
                        message: t("Name must be more than 3 charachters.")
                    }})}></input>
                </div> 
                <div className="mt-4">
                    <label className="c-label">{t("English Name")} <span className="text-red-600 text-sm ms-2">{ errors.name_en?.message ?  (`${errors.name_en.message}`) : ''  }</span></label>
                    <input defaultValue= {''} name="name_en" type="text" className={`c-input mt-1 ${errors.name_en ? 'c-input--has-error' : ''}`} placeholder="" ref={register({required: true, minLength: {
                        value: 3,
                        message: t("Name must be more than 3 charachters.")
                    }})}></input>
                </div> 

                <div className="mt-4">
                    <label className="c-label">{t("Time Duration")} <span className="text-red-600 text-sm ms-2">{ errors.duration?.message ?  (`${errors.duration.message}`) : ''  }</span></label>
                    <input name="duration" type="number" className={`c-input mt-1 ${errors.duration ? 'c-input--has-error' : ''}`} placeholder={t("Minutes")} ref={register({required: true, 
                        min: {
                            value: 1,
                            message: t("At least 1 minute")
                        },
                        max: {
                            value: 1440,
                            message: t("Should be less than 1440 minutes")
                        }})}></input>
                </div>
                
                <div className="mt-4">
                    <label className="c-label">{t("Gender")}</label>
                    <select name="serve" className={`c-input mt-1 ${errors.serve ? 'c-input--has-error' : ''}`} ref={register({required: true})} defaultValue={employee.serve_id}>
                        <option value="1">{t("Male")}</option>
                        <option value="2">{t("Female")}</option>
                    </select>
                </div> 
        
                <div className='mt-4'>
                    <h4>{t("Working Days")} <span className="text-red-600 text-sm ms-2">{ errors.workinHours?.message ?  (`${errors.workinHours.message}`) : ''  }</span></h4>
                    <WorkingHoursTable workingDays={provider.workingDays}></WorkingHoursTable>
                </div>
            </div>

            <div className="flex justify-center relative border-t border-gray-50 pt-4">
                <Button title={t("Save")} loading={isLoading} onClick={handleSubmit(onSubmit, onError)}></Button>
                <button className="px-2 py-1 absolute left-0" onClick={() => onCancel()}>{t("Cancel")}</button>
            </div>
        </div>
    )
}