import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {getUser} from '@services/jwt.service'

export default function ProtectedRoute({component: Component, guards, ...rest}) {
    const currentUser = getUser()
    
    return (
        <Route {...rest} render={props => {
            if (! currentUser) {
                return <Redirect to={{pathname: '/login'}} />
            }
            
            if (guards && guards.indexOf(currentUser.type) === -1) {
                return <Redirect to={{ pathname: '/'}} />
            }

            return <Component {...props} />
        }} />
    )
}