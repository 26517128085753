import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useToasts} from "react-toast-notifications";
import React, {useEffect, useState} from "react";
import Loader from '@components/Loader'
import Layout from '@pages/Layout'
import ApiService from '@services/api.service'
import Button from "../components/Button.js";
import formateDate from "../utils/format-date";
import formatTime from "../utils/format-time";
import Row from '@components/Row'

export default function SingleAppointment() {
    const [appointment, setAppointment] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const {addToast} = useToasts()
    const {t} = useTranslation()
    const {id} = useParams()

    useEffect(() => {
        fetchAppointment()
    }, [id])

    async function fetchAppointment() {
        try {
            setIsLoading(true)
            const response = await ApiService.get(`api/appointments/${id}`)
            setAppointment(response.data.data)
        } catch (e) {
            if (e.response.status == 401) {
                history.push('/')
                return
            }

            if (e.response.status == 404) {
                addToast('Doctor not found.', {appearance: 'error'})
                history.push('/clinics')
                return
            }

            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)
        }
    }

    function AppointmentWrapper() {
        return (
            <div>
                <div className={isLoading ? 'hidden' : undefined}>

                    <div>
                        <Row titleRatio="1/2" title={t("Code")} content={appointment.code}></Row>
                        <Row titleRatio="1/2" title={t("Amount")} content={appointment.amount}></Row>
                        <Row titleRatio="1/2" title={t("Customer")} content={appointment.customer?.name}></Row>
                        <Row titleRatio="1/2" title={t("Specialization")} content={appointment.doctor?.specialization?.title}></Row>
                        <Row titleRatio="1/2" title={t("Clinic")} content={appointment.doctor?.clinic?.name}></Row>
                        <Row titleRatio="1/2" title={t("Doctor")} content={appointment.doctor?.name}></Row>
                        <Row titleRatio="1/2" title={t("Status")} content={appointment.status}></Row>
                        <Row titleRatio="1/2" title={t("Date")} content={formateDate(appointment.start)}></Row>
                        <Row titleRatio="1/2" title={t("Start Time")} content={formatTime(appointment.start)}></Row>
                        <Row titleRatio="1/2" title={t("End Time")} content={formatTime(appointment.end)}></Row>
                        <Row titleRatio="1/2" title={t("Actual Start Time")} content={formatTime(appointment.started_at)}></Row>
                        <Row titleRatio="1/2" title={t("Actual End Time")} content={formatTime(appointment.ended_at)}></Row>
                        <Row titleRatio="1/2" title={t("Join Time")} content={formatTime(appointment.customer_joined_at)}></Row>
                        <Row titleRatio="1/2" title={t("Patient diagnosis")} content={appointment.patient_note}></Row>
                        <Row titleRatio="1/2" title={t("Doctor notes")} content={appointment.doctor_note}></Row>
                    </div>
                </div>
            </div>
        )
    }
    function Content() {
        return isLoading ? <Loader /> : <AppointmentWrapper />
    }
    return (
        <Layout content={<Content/>}></Layout>
    )

}
