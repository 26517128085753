import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'
import {useHistory} from 'react-router-dom'
import axios from "axios"

import Loader from '@components/Loader'
import Button from '@components/Button'
import CardHeader from '@components/CardHeader'
import Layout from '@pages/Layout'
import ImagePlacholder from '@assets/imgs/image-placholder.png'
import store from '../store'
import ApiService from '@services/api.service'
import ClinicFormModal from './ClinicFormModal'

export default function Clinics() {
    const clinics = useSelector(state => state.clinics.clinics)
    const history = useHistory()
    const {t} = useTranslation()
    const {addToast} = useToasts()
    const [editClinic, setEditClinic] = useState({})
    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    function openModal(clinic = {}) {
        setEditClinic(clinic)
        setModalIsOpen(true)
    }

    function closeModal() {
        setModalIsOpen(false)
    }

    useEffect(() => {
        fetchClinics()
    }, [])

    function onSuccess() {
        fetchClinics()
        closeModal()
    }

    async function fetchClinics() {
        try {
            store.dispatch({
                type: 'SET_CLINICS',
                clinics: []
            })

            setLoading(true)

            const response = await ApiService.get(`api/clinics`)

            store.dispatch({
                type: 'SET_CLINICS',
                clinics: response['data']['data']
            })

        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setLoading(false)
        }
    }

    function ClinicsTable({clinics}) {
        return (
            <table className="c-table" style={{minWidth: '1200px'}}>
                <thead>
                    <tr>
                        <th className="px-1 sm:px-4 w-12 border-l border-gray-75 text-center">#</th>
                        <th className="px-1 w-20 sm:px-4">{t("Logo")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("Arabic Name")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("English Name")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Phone Number")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Email")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Active")}</th>
                        <th className="px-1 w-12 sm:px-4">{t("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {clinics.map((clinic, index) => {
                        return (
                            <tr className="hover:bg-gray-25 cursor-pointer" key={index.toString()} onClick={() => history.push(`/clinics/${clinic.id}`)}>
                                <td className="px-2 sm:px-4 py-3 border-l border-gray-50 text-center">{ (index + 1).toString() }</td>
                                <td className="px-2 sm:px-4 py-3 w-20 md:w-40">
                                    <img src={clinic.logo ? clinic.logo  : ImagePlacholder} className="w-20 h-20 rounded-full border border-gray-75 object-cover"></img>
                                </td>
                                <td className="px-2 sm:px-4 py-3 w-20 md:w-40">{clinic.name_ar}</td>
                                <td className="px-2 sm:px-4 py-3">{clinic.name_en}</td>
                                <td className="px-2 sm:px-4 py-3">{clinic.phone}</td>
                                <td className="px-2 sm:px-4 py-3">{clinic.email ? clinic.email : 'NA'}</td>
                                <td className="px-2 sm:px-4 py-3" onClick={e => {
                                    e.stopPropagation()
                                    e.target.querySelector('[type=checkbox]').click();
                                }}>
                                    <input type="checkbox" defaultChecked={clinic.active}
                                                                         onClick={e => e.stopPropagation()}
                                                                         onChange={e=> {
                                                                             let input = e.target
                                                                             e.stopPropagation()
                                                                             input.disabled = true
                                                                             axios.patch(`/api/clinics/${clinic.id}/activation?active=${!clinic.active * 1}`)
                                                                                 .then(() =>{
                                                                                     clinic.active = ! clinic.active
                                                                                     addToast(clinic.active ? t('Activated successfully') : t('Deactivated successfully'), {appearance: 'success'})
                                                                                 })
                                                                                 .catch(e => addToast(e.response.data.message, {appearance: 'error'}))
                                                                                 .finally(() => input.disabled = false)
                                                                         }}
                                    />
                                </td>
                                <td className="px-2 sm:px-4 py-3">
                                    <button className="px-2 py-1 text-xs border border-primary text-primary rounded-md" onClick={(e) => {e.stopPropagation(); openModal(clinic)}}>{t("Edit")}</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    function Content() {
        return (
            <>
                <ClinicFormModal isOpen={modalIsOpen} close={() => closeModal()} onSuccess={() => onSuccess()} clinic={editClinic}/>
                <CardHeader content={
                    <div className="ms-auto">
                        <Button title={ t("Add Clinic") } onClick={() => openModal({})} />
                    </div>
                }/>

                <div className="overflow-x-scroll h-full">
                    <ClinicsTable clinics={clinics} />
                    {
                        loading && <Loader />
                    }
                    {
                        !loading && clinics.length === 0 && (
                            <p className="h-48 flex-center text-lg text-gray-500">{t("No Clinics Were Found.")}</p>
                        )
                    }
                </div>
            </>
        )
    }

    return (
        <Layout key="reservation-layout" content={<Content key="content" />} title={t("Clinics")} />
    )
}
