import ApiService from './api.service'
import store from '../store'
  
import {
    getToken, 
    setToken, 
    destroyToken,
    destroyUser,
    setUser,
} from './jwt.service'

const AuthService = window.Auth = {
    async fetch() {
        try {
            const userResponse = await ApiService.get(`api/authenticated`)
            store.dispatch({type: 'SET_USER', user: userResponse.data.data})
            ApiService.init()
            return userResponse
        } catch (e) {
            debugger
            destroyToken()
            destroyUser()
            throw e
        }
    },

    async login({phone = null, email = null, password, guard}) {
        try {
            const loginResponse = await ApiService.post(`api/authenticate`, {
                phone: phone,
                email: email,
                password: password,
                guard: guard,
            })
            
            const user = loginResponse.data.data.user
            const token = loginResponse.data.data.access_token
            
            if (user.type == 'providers') {
                const providerResponse = await ApiService.get(`api/providers/${user.id}`)
                const provider = providerResponse.data.data
                setUser(provider)
                store.dispatch({type: 'SET_USER', user: provider})
            } else {
                setUser(user)
                store.dispatch({type: 'SET_USER', user: user})
            }
            
            setToken(token)
            ApiService.init()
        } catch (e) {
            throw e
        }
    },

    async logout() {
        try {
            await ApiService.post(`api/logout`, {device_id: 'web'})
            destroyToken()
            destroyUser()
        } catch (e) {
            destroyToken()
            destroyUser()
            throw e
        }
    },

    isAuthenticated() {
        return getToken() !== undefined && getToken() !== null
    }
}

export default AuthService