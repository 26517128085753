import React from 'react'
import Button from './Button'

export default function Pagination({total, current, onClick}) {
    return (
        <div className="flex justify-center py-4 pt-8">
            <ul className="flex">
                {
                    [...Array(total)].map((x, i) => {
                        return (
                            <li className="px-2" key={`pagination-${i}`}>
                                <Button outline={ current == i + 1 } title={i + 1} onClick={() => onClick(i + 1) }></Button>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}  