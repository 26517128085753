import React, {useEffect, useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from "react-router-dom"
import {useToasts} from 'react-toast-notifications'

import Spinner from '@components/Spinner'
import Button from '@components/Button'
import Status from '@components/Status'
import DocumentText from '@components/icons/solid/DocumentText'
import BackButton from '@components/BackButton'
import Row from '@components/Row'
import Loader from '@components/Loader'

import ApiService from '@services/api.service'
import makeReservation from '@models/makeReservation'
import formatDate from '@utils/format-date'
import getHour from '@utils/get-hour'
import Layout from '@pages/Layout'
import useLocale from '@hooks/useLocale'


export default function Reservation() {
    const history = useHistory()
    const {id} = useParams()
    const {addToast} = useToasts()
    const {t} = useTranslation()
    const locale = useLocale()
    
    const [reservation, setReservation] = useState(makeReservation())
    const [isLoading, setIsLoading] = useState(true)
    const [isUploading, setIsUploading] = useState(false)
    const [isConfirming, setIsConfirming] = useState(false)
    
    const uploadResultInput = useRef(null)
    
    useEffect(() => {
        fetchReservation()
    }, [id])

    async function fetchReservation() {
        try {
            setIsLoading(true)
            const response = await ApiService.get(`api/providers/reservations/${id}`)
            setReservation(makeReservation(response['data']['data']))
        } catch(e) {
            if (e.response.status == 404) {
                addToast('Reservation not found.', {appearance: 'error'})
                history.push('/reservations')
                return
            }

            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsLoading(false)
        }
    } 

    async function confirm() {
        try { 
            if (! window.confirm(t("Are you sure you want to confirm?"))) 
                return
            
            setIsConfirming(true)
            await ApiService.post(`/api/providers/reservations/${id}/update-status?statusId=4`)
            addToast(t("Reservation has been confirmed"), {appearance: 'success'})
            setReservation({...reservation, statusNo: 4, status: locale == 'en' ? 'Confirmed' : 'منتهية'})
        } catch(e) {
            addToast('Couldn\'t confirm the reservation.', {appearance: 'error'})
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsConfirming(false)
        }
    }

    async function uploadReault(file) {
        try {
            setIsUploading(true)
            const formData = new FormData()
            formData.append("result", file, file.name)
            formData.append("reservation_id", id)

            const response = await ApiService.post(`/api/providers/reservations/result`, formData)
            setReservation({
                ...reservation, 
                results: [...reservation.results, response['data']['data']]
            })
        } catch(e) {
            addToast('Couldn\'t upload the file.', {appearance: 'error'})
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setIsUploading(false)
        }
    }

    async function removeResult(id) {
        try {
            if (! window.confirm(t("Are you sure you want to delete the result?"))) 
                return
            
            await ApiService.post(`/api/providers/reservations/results/${id}/delete`)
            const results = reservation.results.filter(r => r.id !== id)
            setReservation({
                ...reservation, 
                results: results
            })
        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        }
    }

    function ResultRow({result, index}) {
        return(
            <div className="flex w-full border-b border-gray-100 p-2 hover:bg-gray-50">
                <a href={ result.result } target="_blank" rel="noopener noreferrer" className="w-full flex justify-between" key={result.id}>
                    <p className="flex items-center text-gray-500">
                        <DocumentText className="w-5 h-5 text-yellow-600 me-2"></DocumentText>{t("Result")} { index + 1 }
                    </p>
                </a>
                <a href={ result.result } target="_blank" rel="noopener noreferrer" className="flex-center font-bold text-primary text-sm mx-2">{t("Download")}</a>
                <button onClick={()=>{ removeResult(result.id) }} className="font-bold text-red-600 text-sm mx-2">{t("Delete")}</button>
            </div>
        )
    }

    function VoiceNoteRow({note, index}) {
        return (
            <div className="border-b border-gray-25 py-1"> 
                <audio controls >
                    <source src={note.voice_note} type="audio/ogg" />
                    <source src={note.voice_note} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        )
    }

    function DocumentRow({document, index}) {
        return (
            <a className="flex-center border-gray-75 py-1 hover:bg-gray-25 border me-2 p-1 rounded" href={document.document}> 
            { `${t("Document") } ${index + 1}` }
                <span className="px-2 me-2 border-r border-gray-75">
                    <svg className="h-4 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                </span>
            </a>
        )
    }

    function UploadButton() {
        return (
            <label htmlFor="upload-result">
                <button className={`w-full h-16 flex-center bg-gray-25 hover:bg-gray-50 text-gray-700 border-t border-gray-75 font-bold ${isUploading ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => !isUploading && uploadResultInput.current.click()}>
                    {
                        isUploading ? <Spinner color="var(--color-primary)"></Spinner> :
                            <div className="flex-center">
                                <svg className="w-6 h-6 me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmens="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg> {t("Add New Result")}
                            </div>
                    }
                </button>
                <input 
                    className="hidden" 
                    name="upload-result" 
                    id="upload-result" 
                    ref={uploadResultInput} 
                    type="file" 
                    onChange={e => uploadReault(e.target.files[0])} />
            </label>
        )
    }
    
    function Reservation() {
        return (
            <div>
                <div className="pb-6 border-b border-gray-50">
                    <BackButton className="mr-auto"></BackButton>
                </div>
                
                <h2 className="text-lg md:text-2xl text-center mt-6">{ reservation.service?.name }</h2>
                <h5 className="text-base text-center text-gray-400 mt-1">{ reservation.code }</h5>

                <div className={isLoading ? 'hidden' : undefined}>
                    <div className="flex justify-between border-b border-gray-100 py-2">
                        <p className="font-bold text-primary text-xl py-2">{t("General Information")}</p>
                    </div>
                    
                    <Row title={t("Customer")} content={reservation.customer?.name}></Row>
                    <Row title={t("Reservation Code")} content={reservation.code}></Row>
                    <Row title={t("Reservation Time")} content={`${t("On")} ${formatDate(reservation.start)} ${t("from")} ${getHour(reservation.start)} ${t("to")} ${getHour(reservation.end)}`}></Row>
                    <Row title={t("Category")} content={reservation.category?.name}></Row>
                    <Row title={t("Service")} content={
                        <>
                            { reservation.service?.name }
                            <div className="whitespace-pre-wrap max-w-xl leading-7 text-gray-500 font-normal text-sm">{ reservation.service?.description }</div>
                        </>
                    }></Row>
                    <Row title={t("Gender")} content={reservation.gender}></Row>
                    <Row title={t("Employee")} content={reservation.employee?.name}></Row>
                    <Row title={t("Address")} content={reservation.address}></Row>
                    <Row title={t("Amount")} content={reservation.amount}></Row>
                    <Row title={t("Quantity")} content={reservation.quantity}></Row>
                    <Row title={t("Status")} content={<span className="block w-48"><Status reservation={reservation} className="max-w-xs"></Status></span>}></Row>
                    <Row title={t("Notes")} content={reservation.comment ? reservation.comment : <span className="text-gray-400 font-light text-base">{t("Empty")}</span>}></Row>
                </div>
                <div className="mt-8 border-b border-gray-75 pb-8">
                    <div className="flex justify-between py-2">
                        <p className="font-bold text-primary text-xl pt-2">{t("Medical Documents")}</p>
                    </div>
                    <div className={`rounded mt-2 flex flex-wrap`}>
                        { reservation.documents?.map((document, index) => <DocumentRow key={`document-${index}`} document={document} index={index}></DocumentRow>) }
                        { reservation.documents?.length == 0 && <div className="text-gray-400">{t("No Medical Documents")}</div> }
                    </div>
                </div>
                <div className="mt-8 border-b border-gray-75 pb-8">
                    <div className="flex justify-between py-2">
                        <p className="font-bold text-primary text-xl pt-2">{t("Recordings")}</p>
                    </div>
                    <div className={`rounded mt-2`}>
                        { reservation.voiceNotes?.map((note, index) => <VoiceNoteRow key={`voice-${index}`}  note={note} index={index}></VoiceNoteRow>) }
                        { reservation.voiceNotes?.length == 0 && <div className="text-gray-400">{t("No Recordings")}</div> }
                    </div>
                </div>

                <div className={isLoading ? 'hidden' : 'mt-8'}>
                    <div className="flex justify-between py-2">
                        <p className="font-bold text-primary text-xl pt-2">{t("Results")}</p>
                    </div>
                    <div className={`border rounded border-gray-75 mt-2`}>
                        { reservation.results?.map((result, index) => <ResultRow key={`result-${index}`} result={result} index={index}></ResultRow>) }
                        { reservation.results?.length == 0 && <div className="flex-center h-20 rounded text-gray-400">{t("No Results Were Found")}</div> }

                        <UploadButton></UploadButton>
                    </div>
                    
                    <div className="h-16 pt-4 mt-8 border-t border-gray-50 flex-center">
                        { reservation.statusNo !== 4 && <Button title={t("Done")} loading={isConfirming} onClick={confirm}></Button> }
                    </div>
                </div>
            </div>
        )
    }

    function Content() {
        return isLoading ? <Loader></Loader> : <Reservation></Reservation>
    }

    return (
        <Layout content={<Content />} title={ !isLoading && <> { t("Reservation Title") } #{ reservation.code } </>}></Layout>
    )
} 