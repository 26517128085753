import React, { useState } from 'react'
import {NavLink} from "react-router-dom"
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import Notifications from '@components/Notifications'
import Logout from '@components/icons/outline/Logout'
import logo from '@assets/imgs/laan-white-logo.png'

import AuthService from '@services/auth.service'
import useLocale from '@hooks/useLocale'
import useUser from '@hooks/useUser'

function Locale() {
    const [locale] = useState(useLocale())

    function toggleLocale() {
        localStorage.setItem('locale', locale == 'en' ? 'ar' : 'en')
        window.location.reload()
    }

    return (
        <div className="me-2">
            <button className="text-gray-300 leading-relaxed border px-2 rounded text-sm py-1 border-gray-600" onClick={toggleLocale}>
                { locale == 'en' ? 'عربي' : 'EN' }
            </button>
        </div>
    )

}

function HeaderLink({title, to,}) {
    return <NavLink to={to} activeClassName="bg-gray-900 hover:bg-gray-900 hover:text-white" className="px-2 md:px-3 py-1 md:py-2 rounded-md text-sm font-medium text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 me-1 md:me-4">
                { title }
            </NavLink>
}


export default function Nav() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const user = useUser()

    async function logout() {
        if (! window.confirm(t("Are you sure?"))) {
            return
        }

        await AuthService.logout()
        dispatch({type: 'LOG_OUT'})
        history.push('/')
    }

    return (
        <nav className="bg-primary-darker">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="border-b border-gray-700">
                    <div className="flex items-center justify-between h-16 px-4 ">
                        <div className="flex items-center lg:w-1/4">
                            <div className="flex-shrink-0 hidden md:block">
                                <NavLink to="/reservations">
                                    <img className="w-8 h-auto" src={logo} alt="Logo"/>
                                </NavLink>
                            </div>
                            <div className="md:ms-10 flex items-baseline">
                                { user.isAdmin() && <HeaderLink to="/clinics" title={ t("Clinics") } /> }
                                { user.isAdmin() && <HeaderLink to="/specializations" title={ t("Specializations") } /> }
                                { user.isAdmin() && <HeaderLink to="/appointments" title={ t("Appointments") } /> }
                                { user.isProvider() && <HeaderLink to="/reservations" title={ t("Reservations") } /> }
                                { user.isProvider() && <HeaderLink to="/employees" title={ t("Employees") } /> }
                                { user.isProvider() &&  <HeaderLink to="/settings" title={ t("Settings") } /> }
                            </div>
                        </div>
                        <div className="text-white hidden md:block whitespace-no-wrap text-sm lg:text-base">{ user?.name }</div>
                        <div className="lg:w-1/4 flex justify-end">
                            <div className="ms-2 flex items-center md:ms-6">
                                <Locale></Locale>

                                <Notifications></Notifications>

                                <button className="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 ltr:flip" aria-label="Logout" onClick={logout}>
                                    <Logout className="w-6 h-6 mx-1 transform -scale-1" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
