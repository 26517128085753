import React from 'react'
import getHour from '@utils/get-hour'
import dayOfWeek from '@utils/day-of-week'
import {useTranslation} from 'react-i18next'

export default function WorkingDaysTable({workingDays}) {
    const { t } = useTranslation()
    return (
        <table className="table max-w-2xl border border-gray-100 mb-0">
            <thead>
                <tr>
                    <th></th>
                    <th scope="col" className="text-center">{t("From")}</th>
                    <th scope="col" className="text-center">{t("To")}</th>
                </tr>
            </thead>
            <tbody>
                {
                    workingDays?.map(day => 
                        day.workinHours.map((hour, index) => {
                            return (
                                <tr key={`hour-${hour.id}`}>
                                    { index == 0 && <th className="border-l border-gray-100" scope="row" rowSpan={index == 0 && day.workinHours.length > 0 ? day.workinHours.length : 1}>{ dayOfWeek(day.day) }</th> }
                                    <td className="font-medium text-center">{ getHour(hour.start) }</td>
                                    <td className="font-medium text-center">{ getHour(hour.end) }</td>
                                </tr>
                            )
                        })
                    )
                }
            </tbody>
        </table>
    )
}