import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {useToasts} from 'react-toast-notifications'

import Loader from '@components/Loader'
import Button from '@components/Button'
import CardHeader from '@components/CardHeader'

import Layout from '@pages/Layout'
import store from '../store'
import ApiService from '@services/api.service'
import SpecializationFormModal from './SpecializationFormModal'
import ImagePlacholder from '@assets/imgs/image-placholder.png'
import axios from "axios";

export default function Specializations() {
    const {addToast} = useToasts()
    const {t} = useTranslation()
    const specializations = useSelector(state => state.specializations.specializations)

    const [loading, setLoading] = useState(true)
    const [editSpecilization, setEditSpecilization] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false)

    function openModal() {
        setModalIsOpen(true)
    }

    function closeModal() {
        setModalIsOpen(false)
    }

    function onSuccess() {
        fetchSpecializations()
        closeModal()
    }

    useEffect(() => { fetchSpecializations() }, [])

    function openModalForNew() {
        setEditSpecilization({})
        openModal()
    }
    function openModalForEdit(s) {
        setEditSpecilization(s)
        openModal()
    }

    async function fetchSpecializations() {
        try {
            store.dispatch({
                type: 'SET_SPECIALIZATIONS',
                specializations: []
            })

            setLoading(true)

            const response = await ApiService.get(`api/specializations`)

            store.dispatch({
                type: 'SET_SPECIALIZATIONS',
                specializations: response['data']['data']
            })

        } catch(e) {
            e.toastMessage && addToast(e.toastMessage, {appearance: 'error'})
        } finally {
            setLoading(false)
        }
    }

    function SpecializationsTable({specializations}) {
        return (
            <table className="w-full border border-gray-50 text-sm md:text-base" style={{minWidth: '1200px'}}>
                <thead className="bg-gray-50">
                    <tr className="h-12 text-gray-600 border border-b-2 border-gray-75 text-start">
                        <th className="px-1 sm:px-4 w-12 border-l border-gray-75 text-center">#</th>
                        <th className="px-1 w-12 sm:px-4">{t("Image")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("Arabic Title")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("English Title")}</th>
                        <th className="px-1 w-64 sm:px-4">{t("Duration")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Commission")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Active")}</th>
                        <th className="px-1 w-32 sm:px-4">{t("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {specializations?.map((specialization, index) => {
                        return (
                            <tr className="h-6 border-b border-gray-50" key={index.toString()}>
                                <td className="px-2 sm:px-4 py-3 border-l border-gray-50 text-center">{ (index + 1).toString() }</td>
                                <td className="px-2 sm:px-4 w-20">
                                    <img src={specialization.image ? specialization.image : ImagePlacholder} className="w-8 h-8 rounded-full border border-gray-75 object-cover"></img>
                                </td>
                                <td className="px-2 sm:px-4 py-3 w-20 md:w-40">{specialization.title_ar}</td>
                                <td className="px-2 sm:px-4 py-3">{specialization.title_en}</td>
                                <td className="px-2 sm:px-4 py-3">{specialization.duration} {t("Minute")}</td>
                                <td className="px-2 sm:px-4 py-3">{specialization.commission}%</td>
                                <td className="px-2 sm:px-4 py-3" onClick={e => {
                                    e.stopPropagation()
                                    e.target.querySelector('[type=checkbox]').click();
                                }}>
                                    <input type="checkbox" defaultChecked={specialization.active}
                                           onClick={e => e.stopPropagation()}
                                           onChange={e=> {
                                               let input = e.target
                                               e.stopPropagation()
                                               input.disabled = true
                                               axios.patch(`/api/specializations/${specialization.id}/activation?active=${!specialization.active * 1}`)
                                                   .then(() =>{
                                                       specialization.active = ! specialization.active
                                                       addToast(specialization.active ? t('Activated successfully') : t('Deactivated successfully'), {appearance: 'success'})
                                                       input.disabled = false;
                                                   })
                                           }}
                                    />
                                </td>
                                <td className="px-2 sm:px-4 py-3">
                                    <button className="px-2 py-1 text-xs border border-primary text-primary rounded-md" onClick={() => openModalForEdit(specialization)}>{t("Edit")}</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    function Content() {
        return (
            <div className="overflow-x-scroll h-full">
                <SpecializationFormModal isOpen={modalIsOpen} close={() => closeModal()} onSuccess={() => onSuccess()} specialization={editSpecilization}/>
                <CardHeader content={
                    <div className="ms-auto">
                        <Button title={ t("Add Specilization") } onClick={openModalForNew}></Button>
                    </div>
                }/>
                <SpecializationsTable specializations={specializations}></SpecializationsTable>
                {loading && <Loader />}
                {!loading && specializations.length === 0 && <p className="h-48 flex-center text-lg text-gray-500">{t("No Specializations Were Found.")}</p>}
            </div>
        )
    }

    return (
        <Layout key="reservation-layout" content={<Content key="content" />} title={t("Specializations")}></Layout>
    )
}
